import { useContext } from "react";
import { ActividadButton } from "../Components/Buttons/ButtonModals";
import { TableDocenteActividad } from "../Components/Table/TablaDocenteActividad";
import useAuth from "../hooks/useAuth";
import { useParams } from "react-router-dom";
import { TableParticipante } from "../Components/Table/TablaParticipantes";
import { ActividadInfo } from "../Components/ActividadInfo/ActividadInfo";
import { TablaParticipanteDocente } from "../Components/Table/TablaParticipanteDocente";

export function ActividadesDocentes() {
  const { actividad_id } = useParams();
  console.log(
    "ActividadesDocentes renderizado con actividad_id:",
    actividad_id
  );

  const { auth } = useAuth();
  //console.log(auth); // Verifica si 'roles' está presente en la consola

  let admin;
  if (auth.roles === "docente") {
    admin = false;
  } else {
    admin = true;
  }

  return (
    <div className="container main-body pb-3 my-5">
      <ActividadInfo actividadId={actividad_id} />

      {admin ? (
        <div>
          <h1>Lista de Docentes</h1>
          <p>
            Marque la casilla para agregar o retirar un docente de la actividad.
          </p>
          <div className="table-container container mb-3">
            <TableDocenteActividad actividadId={actividad_id} admin={true} />
          </div>
          <div>
            <h1>Lista de Participantes</h1>
            <p>Lista de participantes registrados a la actividad.</p>
            <div className="table-container container">
              <TableParticipante actividadId={actividad_id} admin={true} />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h1>Lista de Participantes</h1>

          <p>Marque la casilla para registrar la asistencia a la actividad.</p>
          <div className="table-container container">
            <TablaParticipanteDocente
              id={auth.user_id}
              actividadId={actividad_id}
              admin={false}
            />
          </div>
        </div>
      )}
    </div>
  );
}
