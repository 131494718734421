import "./table.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ParticipanteButton } from "../Buttons/ButtonModals";
import useAuth from "../../hooks/useAuth";
export function TablaDocenteParticipante({ id }) {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { auth } = useAuth();
  const role = auth.roles;
  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        response = await fetch(
          `https://appexploratarapaca.com/sdbmg/participantes/User=${id}`
        );
        /*if (role === "docente") {
          response = await fetch(
            `https://appexploratarapaca.com/sdbmg/participantes/User=${id}`
          );
        } */ /*else {
          console.error("Falta Autorización");
          return; // Return early if role is not "docente"
        }*/

        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.status}`);
        }

        const jsonData = await response.json();

        if (Array.isArray(jsonData)) {
          setData(jsonData);
        } else {
          console.error("Received data is not an array:", jsonData);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [role, id]);

  const handleRowClick = (participante_id) => {
    // Navegar a la URL especificada cuando se hace clic en una fila
    navigate(`/participantes/${participante_id}`);
  };

  return (
    <div className="rounded tb-container" style={{ margin: "20px" }}>
      <div className="search-container">
        <input
          className="search-input"
          type="text"
          placeholder="Ingrese Nombre, Apelldios, RUT, DNI para buscar participante..."
        />
      </div>
      <table className="caption-top table rounded table-sm table-bordered table-hover table-striped">
        <caption>Lista de Participantes</caption>
        <thead className="table-primary">
          <tr>
            <th>Apellidos</th>
            <th>Nombre</th>
            <th>Ciclo</th>
            <th>Curso</th>
            <th>Rut o DNI</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr
              key={item.participante_id}
              onClick={() => handleRowClick(item.participante_id)}
              style={{ cursor: "pointer" }}
            >
              <td>{`${item.apellido_p}, ${item.apellido_m}`}</td>
              <td>{item.nombre}</td>
              <td>{item.ciclo}</td>
              <td>{item.curso}</td>
              <td>{item.dni || `${item.rut}-${item.dig_verificador}`}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-flex align-items-end">
        <div className="d-flex flex-grow-1 align-items-center justify-content-between mx-1">
          <ParticipanteButton />
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <a className="page-link" href="#">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              {/* <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li> */}
              <li className="page-item">
                <a className="page-link" href="#">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
