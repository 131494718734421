import { useEffect, useState } from "react";
import "../ActividadInfo/ActividadInfo.css";
import { UpdateActividadButton } from "../Buttons/ButtonModals";
import useAuth from "../../hooks/useAuth";

export function ActividadInfo({ actividadId }) {
  const [data, setData] = useState([]); //
  const [loading, setLoading] = useState(true);
  const { auth } = useAuth();
  
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener datos para docentes
        const docentesResponse = await fetch(
          `https://appexploratarapaca.com/sdbmg/actividades/ById/${actividadId}`
        );
        const docentesData = await docentesResponse.json();

        setData(docentesData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [actividadId]);

  if (loading || data.length === 0) {
    return <p>Cargando...</p>;
  }

  const isAdmin = auth.roles && auth.roles.includes("admin");

  return (
    <div>
      <h1>{data[0].nombre || "Nombre no disponible"}</h1>
      <div className="d-flex justify-content-center flex-row gap-3">
        <div className="placeholder-img">
          <img id="act_img" src={`/uploads/${data[0].imagen}`} />
        </div>
        <div className="align-self-center">
          <p>{data[0].descripcion}</p>
          <div className="d-flex flex-row gap-4">
            <p>{data[0].estado}</p>
            <p>
              {data[0].fecha_evento &&
                // Formatear la fecha si fecha_evento está disponible
                new Date(data[0].fecha_evento).toLocaleDateString("es-ES", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
            </p>
          </div>
        </div>
      </div>
      {isAdmin && (
        <div className="my-3">
          <UpdateActividadButton actividadId={actividadId} />
        </div>
      )}
      <div className="my-3">
        <a
          type="button"
          href={`/uploads/${data[0].permiso}`}
          download={`${data[0].nombre}-CesionImagen`}
          target="_blank" // Set target attribute to "_blank"
          rel="noopener noreferrer"
        >
          <button type="button" className="btn btn-primary">
            Documento cesión de Imagen
          </button>
        </a>
      </div>
    </div>
  );
}
