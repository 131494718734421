import "./table.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActividadButton } from "../Buttons/ButtonModals";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";


export function TableActividades({ id, admin }) {
  const [data, setData] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 15;
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (admin) {
          response = await fetch(
            `/actividades`
          );
        } else {
          console.error("Falta Autorizacion");
          return
        }

        if (response.status == 200) {
          throw new Error(`Error fetching data: ${response.status}`);
        }

        const jsonData = response.data;

        // Check if jsonData is an array
        if (Array.isArray(jsonData)) {
          setData(jsonData);
        } else {
          // If jsonData is not an array, handle it accordingly
          console.error("Received data is not an array:", jsonData);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [admin, id]);

  const handleRowClick = (actividad_id) => {
    // Navigate to the specified URL when a row is clicked
    navigate(`/actividades/${actividad_id}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US");
  };

  const renderRows = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const filteredData = data.filter(
      (item) =>
        item.nombre.toLowerCase().includes(searchQuery.toLowerCase()) ||
        formatDate(item.fecha_evento)
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.descripcion.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.estado.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return filteredData.slice(startIndex, endIndex).map((item) => (
      <tr
        key={item.actividad_id}
        onClick={() => handleRowClick(item.actividad_id)}
        style={{ cursor: "pointer" }}
      >
        <td>{item.nombre}</td>
        <td>{formatDate(item.fecha_evento)}</td>
        <td>{item.descripcion}</td>
        <td>{item.estado}</td>
      </tr>
    ));
  };

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }
    return (
      <nav aria-label="Page navigation example ">
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <span aria-hidden="true">&laquo;</span>
            </button>
          </li>
          {pageNumbers}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <span aria-hidden="true">&raquo;</span>
            </button>
          </li>
        </ul>
      </nav>
    );
  };

  return (
    <div className="rounded tb-container" style={{ margin: "20px" }}>
      <div className="search-container">
        <input
          className="search-input"
          type="text"
          placeholder="Ingrese Nombre, Descripción o Estado para buscar actividad..."
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
      <table className="caption-top table rounded table-sm table-bordered table-hover table-striped">
        <caption>Lista de Actividades</caption>
        <thead className="table-primary">
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Fecha</th>
            <th scope="col">Descripción</th>
            <th scope="col">Estado Act.</th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
      <div className="d-flex  align-items-end">
        <div className="d-flex flex-grow-1 justify-content-between align-items-center mx-1">
          <ActividadButton />
          {renderPagination()}
        </div>
      </div>
    </div>
  );
}
