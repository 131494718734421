import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const LogoutLink = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setAuth } = useAuth();

  const handleLogout = () => {
    navigateToLogin();
    clearAuth();
  };

  const navigateToLogin = () => {
    navigate("/", { state: { from: location }, replace: true });
  };

  const clearAuth = () => {
    setAuth({});
  };

  return (
    <span onClick={handleLogout} style={{ cursor: "pointer" }}>
      Cerrar sesión
    </span>
  );
};
export { LogoutLink };
