import { DocenteButton } from "../Components/Buttons/ButtonModals";
import { TableDocente } from "../Components/Table/TablaDocente";

export function Docentes() {
  const admin = false;
  return (
    <div className="container main-body pb-3 my-5">
      <h1>Docentes</h1>

      {admin ? (
        <p>Seleccione docentes para modificar datos</p>
      ) : (
        <p>Seleccione docentes para modificar datos</p>
      )}
      <div className="table-container container">
        <TableDocente />
      </div>
    </div>
  );
}
