import { useContext, useEffect } from "react";
import { TableActividades } from "../Components/Table/TablaActividades";
import AuthContext from "../context/AuthProvider";
import useAuth from "../hooks/useAuth";
import { TableActividadDocente } from "../Components/Table/TablaActividadDocente";

export function Actividades() {
  const { auth, loading } = useAuth();

  useEffect(() => {
    console.log("Auth en useEffect:", auth);
  }, [auth]);

  console.log("Auth fuera de useEffect:", auth);

  if (loading) {
    // Opcionalmente, puedes renderizar un indicador de carga aquí
    return <p>Cargando...</p>;
  }

  let admin;
  if (auth.roles === "docente") {
    admin = false;
  } else {
    admin = true;
  }

  return (
    <div className="container main-body pb-3 my-5">
      <h1>Actividades</h1>

      {admin ? (
        <div>
          <p>
            Seleccione la actividad para ver mas detalles o haga clic en el
            botón "Agregar Actividad" para ingresar una nueva actividad.
          </p>
          <div className="table-container container">
            <TableActividades id={auth.user_id} admin={true} />
          </div>
        </div>
      ) : (
        <div>
          <p>
            Seleccione una actividad para ver mas detalles y añadir
            participantes.
          </p>
          <div className="table-container container">
            <TableActividadDocente id={auth.user_id} admin={false} />
          </div>
        </div>
      )}
    </div>
  );
}
