import { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import "./modal.css";
import Axios from "axios";
const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

export function ModalAddParticipante() {
  const { auth } = useAuth();
  const [selectedOption, setSelectedOption] = useState("1");
  const [provincias, setProvincias] = useState([]);
  const [comunas, setComunas] = useState([]);

  const handleModalClose = () => {
    // Recargar la página
    window.location.reload();
  };

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [formData, setFormData] = useState({
    tipo_user: "",
    documento_tipo: "1",
    rut: "",
    dig_verificador: "",
    dni: "",
    nombre: "",
    apellido_p: "",
    apellido_m: "",
    ano_nacimiento: 2010,
    sexo: "Otro",
    especialidad: "",
    email: "",
    telefono: "",
    ciclo: "",
    curso: "",
    region: "",
    provincia: "",
    comuna: "",
    rbd: "",
    seg_nombre: "",
    establecimiento: "",
  });

  const [errors, setErrors] = useState({});
  const [exito, setExito] = useState("");

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.nombre.trim()) {
      newErrors.nombre = "Primer Nombre es requerido";
      isValid = false;
    }

    if (!emailRegex.test(formData.email) && formData.email) {
      newErrors.email = "Please enter a valid email address";
      isValid = false;
    }

    if (!formData.apellido_p.trim()) {
      newErrors.apellido_p = "Apellido Paterno es requerido";
      isValid = false;
    }
    if (!formData.apellido_m.trim()) {
      newErrors.apellido_p = "Apellido Materno es requerido";
      isValid = false;
    }

    if (selectedOption == "0" && !formData.dni) {
      newErrors.dni = "DNI es requerido";
      isValid = false;
    }
    if (selectedOption == "1" && !formData.rut) {
      newErrors.rut = "RUT es requerido";
      isValid = false;
      
    }
    if (selectedOption == "1" && !formData.dig_verificador) {
      newErrors.dig_verificador = "Digito verificador es requerido";
      isValid = false;

    }

    if (!formData.tipo_user) {
      newErrors.tipo_user = "Tipo de Participante es requerido";
      isValid = false;
    }

    if (!formData.ano_nacimiento || formData.ano_nacimiento <= 1900) {
      newErrors.ano_nacimiento = "Ingrese un año valido";
      isValid = false;
    }

    if (!formData.sexo) {
      newErrors.sexo = "Género es requerido";
      isValid = false;
    }

    if (!formData.rut.trim()) {
      newErrors.rut = "RUT es requerido";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  useEffect(() => {
    // Obtener el modal por su ID
    const modal = document.getElementById("modalAddParticipante");
    // Añadir un listener para el evento 'hidden.bs.modal'
    modal.addEventListener("hidden.bs.modal", handleModalClose);

    // Limpiar el listener cuando el componente se desmonte
    return () => {
      modal.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []); // La dependencia vacía asegura que este efecto solo se ejecute una vez

  const agregarParticipante = () => {
    if (!validateForm()) return;
let url = `https://appexploratarapaca.com/sdbmg/participantes/${auth.user_id}`
if (formData.tipo_user === 'Admin') {
  url = `https://appexploratarapaca.com/sdbmg/usuarios`
}
else{
  url = `https://appexploratarapaca.com/sdbmg/participantes/${auth.user_id}`

}
    Axios.post(
      url,
      {
        tipo_user: formData.tipo_user, //
        documento_tipo: parseInt(selectedOption), //
        rut: formData.rut, //
        dig_verificador: formData.dig_verificador, //
        dni: formData.dni, //
        nombre: formData.nombre, //
        apellido_p: formData.apellido_p, //
        apellido_m: formData.apellido_m, //
        ano_nacimiento: formData.ano_nacimiento, //
        sexo: formData.sexo, //
        especialidad: formData.especialidad, //
        email: formData.email, //
        telefono: formData.telefono, //
        ciclo: formData.ciclo, //
        curso: formData.curso, //
        region: formData.region, //
        provincia: formData.provincia, //
        comuna: formData.comuna,
        rbd: formData.rbd, //
        seg_nombre: formData.seg_nombre, //
        establecimiento: formData.establecimiento, //
      }
    )
      .then(() => {
        setExito("¡Participante agregado con éxito!");
        console.log("éxito");
      })
      .catch((error) => {
        console.error("Error al agregar participante:", error);
      });
  };
  const handleRegionChange = async (e) => {
    const selectedRegion = e.target.value;
    setFormData({ ...formData, region: selectedRegion });
    try {
      const response = await fetch(
        `https://appexploratarapaca.com/sdbmg/comunas/provincia/${selectedRegion}`
      );
      const provincesData = await response.json();
      setProvincias(provincesData);
    } catch (error) {
      console.error("Error fetching provinces:", error);
    }
  };

  const handleProvinciaChange = async (e) => {
    const selectedProvincia = e.target.value;
    setFormData({ ...formData, provincia: selectedProvincia });

    try {
      const response = await fetch(
        `https://appexploratarapaca.com/sdbmg/comunas/comuna/${selectedProvincia}`
      );
      const comunasData = await response.json();
      setComunas(comunasData);
    } catch (error) {
      console.error("Error fetching Comunas:", error);
    }
  };

  return (
    <div
      className="modal fade"
      id="modalAddParticipante"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog" id="modalWindow">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Agregar Participante
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          {/*-----------------Forumalrio-----------*/}
          <form id="body-add-alumno" className="modal-body">
            <div className="row gy-3 row-cols-2 row-cols-lg-4 mb-3">
              <div className="col">
                <label className="form-label">Primer Nombre(*)</label>
                <input
                  type="text"
                  className={`form-control ${errors.nombre && "is-invalid"}`}
                  value={formData.nombre}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]*$/.test(inputValue)) {
                      setFormData({ ...formData, nombre: inputValue });
                    }
                  }}
                />
                {errors.nombre && (
                  <div className="invalid-feedback">{errors.nombre}</div>
                )}
              </div>
              <div className="col">
                <label className="form-label">Segundo Nombre</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.seg_nombre}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]*$/.test(inputValue)) {
                      setFormData({ ...formData, seg_nombre: inputValue });
                    } else {
                      // Handle invalid input
                      console.log("Invalid input for Segundo Nombre");
                    }
                  }}
                />
              </div>
              <div className="col">
                <label className="form-label">Apellido Paterno(*)</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.apellido_p && "is-invalid"
                  }`}
                  value={formData.apellido_p}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]*$/.test(inputValue)) {
                      setFormData({ ...formData, apellido_p: inputValue });
                    } else {
                      // Handle invalid input
                      console.log("Invalid input for Apellido Paterno");
                    }
                  }}
                />
                {errors.apellido_p && (
                  <div className="invalid-feedback">{errors.apellido_p}</div>
                )}
              </div>
              <div className="col">
                <label className="form-label">Apellido Materno(*)</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.apellido_m && "is-invalid"
                  }`}
                  value={formData.apellido_m}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]*$/.test(inputValue)) {
                      setFormData({ ...formData, apellido_m: inputValue });
                    } else {
                      // Handle invalid input
                      console.log("Invalid input for Apellido Materno");
                    }
                  }}
                />
                {errors.apellido_m && (
                  <div className="invalid-feedback">{errors.apellido_m}</div>
                )}
              </div>
            </div>
            <div className="row gy-3 row-cols-2 mb-3 row-cols-lg-4">
              <div className="col">
                <label className="form-label">Año de Nacimiento(*)</label>
                <input
                  type="number"
                  min="1900"
                  max="2099"
                  step="1"
                  maxLength="4"
                  className="form-control"
                  value={formData.ano_nacimiento}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value);
                    if (newValue <= 2099 || e.target.value == "") {
                      setFormData({
                        ...formData,
                        ano_nacimiento: newValue,
                      });
                    } else {
                      setFormData({
                        ...formData,
                        ano_nacimiento: 2000,
                      });
                    }
                  }}
                />
              </div>
              <div className="col">
                <label className="form-label">Sexo(*)</label>
                <select
                  id="sexoSelect"
                  value={formData.sexo}
                  onChange={(e) =>
                    setFormData({ ...formData, sexo: e.target.value })
                  }
                  className="form-select"
                >
                  <option value={"Hombre"}>Hombre</option>
                  <option value={"Mujer"}>Mujer</option>
                  <option selected value={"Otro"}>
                    Otro
                  </option>
                  <option value={"Prefiere no decirlo"}>
                    Prefiere no decirlo
                  </option>
                  <option value={"Sin información"}>Sin información</option>
                </select>
              </div>
              <div className="col">
                <label className="form-label">
                  Tipo de Documento de Identificación
                </label>
                <select
                  id="optionsSelect"
                  className="form-select"
                  onChange={(e) => setSelectedOption(e.target.value)}
                >
                  <option value="1" selected>
                    RUT
                  </option>
                  <option value="0">DNI</option>
                </select>
              </div>
              {selectedOption === "1" && (
                <div className="option d-inline-flex">
                  <div className="col">
                    <label className="form-label">RUT(*)</label>
                    <input
                      type="text"
                      className={`form-control ${errors.rut && "is-invalid"}`}
                      value={formData.rut}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^\d*$/.test(inputValue)) {
                          setFormData({
                            ...formData,
                            rut: inputValue, dni: ""
                          });
                        } else {
                          // Handle invalid input
                          console.log("Invalid input for Digito Verificador");
                        }
                      }}
                    />
                    {errors.rut && (
                      <div className="invalid-feedback">{errors.rut}</div>
                    )}
                  </div>
                  <div className="col justify-content-center text-center">
                    <label className="form-label">Digito Verificador(*)</label>
                    <input
                      id="dig_verificador"
                      type="text"
                      className={`form-control d-flex flex-shrink-1 flex-grow-0 mx-auto ${
                        errors.dig_verificador && "is-invalid"
                      }`}
                      maxlength="1"
                      style={{ width: "50px", minWidth: "50px" }}
                      value={formData.dig_verificador}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^[0-9kK]*$/.test(inputValue)) {
                          setFormData({
                            ...formData,
                            dig_verificador: inputValue, dni: ""
                          });
                        } else {
                          // Handle invalid input
                          console.log("Invalid input for Digito Verificador");
                        }
                      }}
                    />
                    {errors.dig_verificador && (
                      <div className="invalid-feedback">
                        {errors.dig_verificador}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {selectedOption === "0" && (
                <div className="option d-inline-flex">
                  <div className="col">
                    <label className="form-label">DNI(*)</label>
                    <input
                      type="text"
                      className={`form-control ${errors.dni && "is-invalid"}`}
                      value={formData.dni}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^\d+$/.test(inputValue)) {
                          setFormData({ ...formData, dni: inputValue, rut:"", dig_verificador:""});
                        } else {
                          // Handle invalid input
                          console.log("Invalid input for DNI");
                        }
                      }}
                    />
                    {errors.dni && (
                      <div className="invalid-feedback">{errors.dni}</div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="row gy-3 row-cols-2 mb-3 row-cols-lg-4">
              <div className="col">
                <label className="form-label">Teléfono</label>
                <div className="input-group">
                  <span className="input-group-text">(+56)</span>
                  <input
                    type="text"
                    className="form-control"
                    value={formData.telefono}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue)) {
                        setFormData({ ...formData, telefono: inputValue });
                      } else {
                        // Handle invalid input
                        console.log("Invalid input for Teléfono");
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col">
                <label className="form-label">Correo Electrónico</label>
                <input
                  type="email"
                  className="form-control"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </div>
              <div className="col">
                <label className="form-label">Tipo de Participante(*)</label>
                {auth.roles == "admin" && (
                  <select
                    id="userClassAdmin"
                    className="form-select"
                    defaultValue={""}
                    onChange={(e) =>
                      setFormData({ ...formData, tipo_user: e.target.value })
                    }
                  >
                    <option value="" selected>
                    Seleccion Tipo de Participante
                  </option>
                    <option value="Admin">Admin</option>
                    <option value="Científico(a)">Científico(a)</option>
                    <option value="Coautor(a)">Coautor(a)</option>
                    <option value="Directivo(a)">Directivo(a)</option>
                    <option value="Docente">Docente</option>
                    <option value="Educador(a) diferencial">
                      Educador(a) diferencial
                    </option>
                    <option value="Educador(a) Párvulos">
                      Educador(a) Párvulos
                    </option>
                    <option value="Evaluador(a)">Evaluador(a)</option>
                    <option value="Otro">Otro</option>
                    <option value="Párvulo(a)">Párvulo(a)</option>
                    <option value="Publico General">Público General</option>
                    <option value="Técnico(a) Educación Parvulario">
                      Técnico(a) Educación Parvulario
                    </option>
                  </select>
                )}
                {auth.roles != "admin" && (
                  <select
                    id="userClassNotAdmin"
                    className="form-select"
                    defaultValue={""}
                    onChange={(e) =>
                      setFormData({ ...formData, tipo_user: e.target.value })
                    }
                  >
                    <option value="" selected>
                    Seleccion Tipo de Participante
                  </option>
                    <option value="Estudiante Básica">
                      Estudiante Básica
                    </option>
                    <option value="Estudiante Media">Estudiante Media</option>
                    <option value="Estudiante Universitario">
                      Estudiante Universitario
                    </option>
                  </select>
                )}
              </div>
              <div className="col">
                <label className="form-label">Especialidad</label>
                <input
                  type="text"
                  value={formData.especialidad}
                  onChange={(e) => {
                    
                    
                      setFormData({ ...formData, especialidad: e.target.value });
                    
                  }}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row gy-3 row-cols-3 mb-3 row-cols-lg-5">
              <div className="col">
                <label className="form-label">Ciclo</label>
                <select
                  id="cicloSelect"
                  className="form-select"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      ciclo: e.target.value,
                    })
                  }
                >
                  <option value="No Aplica" selected>
                    No Aplica
                  </option>
                  <option value="Primer Ciclo Básico">
                    Primer Ciclo Básico
                  </option>
                  <option value="Segundo Ciclo Básico">
                    Segundo Ciclo Básico
                  </option>
                  <option value="Ciclo Básico General">
                    Ciclo Básico General
                  </option>
                  <option value="Educación Media (Científico Humanista)">
                    Educación Media (Científico Humanista)
                  </option>
                  <option value="Educación Media (Técnico-Profesional)">
                    Educación Media (Técnico-Profesional)
                  </option>
                  <option value="Educación Parvularia">
                    Educación Parvularia
                  </option>
                </select>
              </div>
              <div className="col">
                <label className="form-label">Curso</label>
                {formData.ciclo === "" && (
                  <select
                    id="cursoSelect"
                    className="form-select"
                    onChange={(e) =>
                      setFormData({ ...formData, curso: e.target.value })
                    }
                  >
                    <option value="No Aplica" selected>
                      No Aplica
                    </option>
                  </select>
                )}
                {formData.ciclo === "Ciclo Básico General" && (
                  <select
                    id="cursoSelect"
                    className="form-select"
                    defaultValue={""}
                    onChange={(e) =>
                      setFormData({ ...formData, curso: e.target.value })
                    }
                  >
                    <option value="Multigrado" selected>
                      Multigrado
                    </option>
                  </select>
                )}
                {formData.ciclo === "Primer Ciclo Básico" && (
                  <select
                    id="cursoSelect"
                    className="form-select"
                    defaultValue={""}
                    onChange={(e) =>
                      setFormData({ ...formData, curso: e.target.value })
                    }
                  >
                    <option value="1° Básico">1° Básico</option>
                    <option value="2° Básico">2° Básico</option>
                    <option value="3° Básico">3° Básico</option>
                    <option value="4° Básico">4° Básico</option>
                    <option value="Curso Multigrado">Curso Multigrado</option>
                  </select>
                )}
                {formData.ciclo === "Segundo Ciclo Básico" && (
                  <select
                    id="cursoSelect"
                    className="form-select"
                    defaultValue={""}
                    onChange={(e) =>
                      setFormData({ ...formData, curso: e.target.value })
                    }
                  >
                    <option value="5° Básico">5° Básico</option>
                    <option value="6° Básico">6° Básico</option>
                    <option value="7° Básico">7° Básico</option>
                    <option value="8° Básico">8° Básico</option>
                    <option value="Curso Multigrado">Curso Multigrado</option>
                  </select>
                )}
                {formData.ciclo ===
                  "Educación Media (Científico Humanista)" && (
                  <select
                    id="cursoSelect"
                    className="form-select"
                    defaultValue={""}
                    onChange={(e) =>
                      setFormData({ ...formData, curso: e.target.value })
                    }
                  >
                    <option value="1° Medio">1° Medio</option>
                    <option value="2° Medio">2° Medio</option>
                    <option value="3° Medio">3° Medio</option>
                    <option value="4° Medio">4° Medio</option>
                  </select>
                )}
                {formData.ciclo === "Educación Media (Técnico-Profesional)" && (
                  <select
                    id="cursoSelect"
                    className="form-select"
                    defaultValue={""}
                    onChange={(e) =>
                      setFormData({ ...formData, curso: e.target.value })
                    }
                  >
                    <option value="1° Medio">1° Medio</option>
                    <option value="2° Medio">2° Medio</option>
                    <option value="3° Medio">3° Medio</option>
                    <option value="4° Medio">4° Medio</option>
                  </select>
                )}
                {formData.ciclo === "Educación Parvularia" && (
                  <select
                    id="cursoSelect"
                    className="form-select"
                    defaultValue={""}
                    onChange={(e) =>
                      setFormData({ ...formData, curso: e.target.value })
                    }
                  >
                    <option value="Heterogéneo">Heterogéneo</option>
                    <option value="Nivel Medio Mayor">Nivel Medio Mayor</option>
                    <option value="Nivel Medio Menor">Nivel Medio Menor</option>
                    <option value="Sala Cuna Mayor">Sala Cuna Mayor</option>
                    <option value="Sala Cuna Menor">Sala Cuna Menor</option>
                    <option value="Transición 1 (Prekínder)">
                      Transición 1 (Prekínder)
                    </option>
                    <option value="Transición 2 (Kínder)">
                      Transición 2 (Kínder)
                    </option>
                  </select>
                )}
              </div>
              <div className="col">
                <label className="form-label">Región(*)</label>
                <select
                  id="regionSelect"
                  value={formData.region}
                  onChange={handleRegionChange}
                  className="form-select"
                >
                  <option value="" selected>
                    Seleccion Región
                  </option>
                  <option value="Tarapacá">Tarapacá</option>
                  <option value="Antofagasta">Antofagasta</option>
                  <option value="Atacama">Atacama</option>
                  <option value="Coquimbo">Coquimbo</option>
                  <option value="Valparaíso">Valparaíso</option>
                  <option value="Metropolitana de Santiago">
                    Metropolitana de Santiago
                  </option>
                  <option value="Libertador General Bernardo O'Higgins">
                    Libertador General Bernardo O'Higgins
                  </option>
                  <option value="Maule">Maule</option>
                  <option value="Ñuble">Ñuble</option>
                  <option value="Biobío">Biobío</option>
                  <option value="La Araucanía">La Araucanía</option>
                  <option value="Los Ríos">Los Ríos</option>
                  <option value="Los Lagos">Los Lagos</option>
                  <option value="Aysén del General Carlos Ibáñez del Campo">
                    Aysén del General Carlos Ibáñez del Campo
                  </option>
                  <option value="Magallanes y de la Antártica Chilena">
                    Magallanes y de la Antártica Chilena
                  </option>
                </select>
              </div>
              <div className="col">
                <label className="form-label">Provincia(*)</label>
                <select
                  id="provinciaSelect"
                  value={formData.provincia}
                  onChange={handleProvinciaChange}
                  className="form-select"
                >
                  <option value="" selected>
                    Seleccion Provincia
                  </option>
                  {provincias.map((provincia) => (
                    <option
                      key={provincia.provincia_id}
                      value={provincia.nombre_provincia}
                    >
                      {provincia.nombre_provincia}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col">
                <label className="form-label">Comuna(*)</label>
                <select
                  id="comunaSelect"
                  value={formData.comuna}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      comuna: e.target.value,
                    })
                  }
                  className="form-select"
                >
                  <option value="" selected>
                    Seleccion Comuna
                  </option>
                  {comunas.map((comuna) => (
                    <option key={comuna.comuna_id} value={comuna.comuna_nombre}>
                      {comuna.comuna_nombre}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row gy-3 row-cols-3 mb-3 justify-content-start">
              <div className="col">
                <label htmlFor="CodRBD" className="form-label">
                  RBD
                </label>
                <input
                  type="text"
                  className="form-control "
                  id="CodRBD"
                  value={formData.rbd}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue)) {
                      setFormData({
                        ...formData,
                        rbd: inputValue,
                      });
                    } else {
                      // Handle invalid input
                      console.log("Invalid input for Digito Verificador");
                    }
                  }}
                />
              </div>
              <div className="col">
                <label htmlFor="establecimiento" className="form-label">
                  Establecimiento
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="establecimiento"
                  value={formData.establecimiento}
                  onChange={(e) => {
                    
                    
                      setFormData({ ...formData, establecimiento: e.target.value });
                    
                  }}
                />
              </div>
            </div>
          </form>
          <div className="modal-footer">
            <button
              type="button"
              onClick={agregarParticipante}
              className="btn btn-primary"
            >
              Agregar Participante
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
          {Object.keys(errors).length > 0 && (
            <div className="alert alert-danger" role="alert">
              Por favor complete todos los campos obligatorios.
            </div>
          )}
          {exito && (
            <div className="alert alert-success" role="alert">
              {exito}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
