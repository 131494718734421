import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import useAuth from "../hooks/useAuth";
import { TablaFamiliares } from "../Components/Table/TablaFamiliares";

export function ParticipanteInfo() {
  const { auth } = useAuth();
  const { participante_id } = useParams();
  const [selectedOption, setSelectedOption] = useState("1");
  const [security, setSecurityQuestion] = useState("1");
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});
  const [modifiedFields, setModifiedFields] = useState({});
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const participanteInfo = await fetch(
          `https://appexploratarapaca.com/sdbmg/participantes/participante=${participante_id}`
        );
        const participanteData = await participanteInfo.json();

        setData(participanteData);

        setFormData({
          tipo_user: participanteData.tipo_user, //
          documento_tipo: parseInt(participanteData.documento_tipo), //
          rut: participanteData.rut, //
          dig_verificador: participanteData.dig_verificador, //
          dni: participanteData.dni, //
          nombre: participanteData.nombre, //
          apellido_p: participanteData.apellido_p, //
          apellido_m: participanteData.apellido_m, //
          ano_nacimiento: participanteData.ano_nacimiento, //
          sexo: participanteData.sexo, //
          especialidad: participanteData.especialidad, //
          email: participanteData.email, //
          telefono: participanteData.telefono, //
          ciclo: participanteData.ciclo, //
          curso: participanteData.curso, //
          region: participanteData.region, //
          provincia: participanteData.provincia, //
          comuna: participanteData.comuna,
          rbd: participanteData.rbd, //
          seg_nombre: participanteData.seg_nombre, //
          establecimiento: participanteData.establecimiento, //
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [participante_id]);

  useEffect(() => {
    setModifiedFields({});
  }, [data]);

  const handleInputChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
    setModifiedFields((prevModifiedFields) => ({
      ...prevModifiedFields,
      [fieldName]: value !== data[fieldName],
    }));
  };

  const handleDelete = () => {
    Axios.delete(
      `https://appexploratarapaca.com/sdbmg/participantes/${participante_id}`
    )
      .then(() => {
        setSuccessMessage("¡El participante se elimino correctamente!");
      })
      .catch((error) => {
        console.error("Error al eliminar participante:", error);
      });
  };

  const handleToUser = () => {
    let isValid = true;
    const newErrors = {};
    let sendEmail = formData.email;
    if (!formData.email) {
      if (!data[0].email) {
        newErrors.email = "Correo Electronico es requerido";
        isValid = false;
        setErrors(newErrors);
        return isValid;
      } else {
        sendEmail = data[0].email;
      }
    }
    Axios.post(
      `https://appexploratarapaca.com/sdbmg/participantes/toUser=${participante_id}`,
      { email: sendEmail }
    )
      .then(() => {
        setSuccessMessage("¡El participante se volvio usuario correctamente!");
      })
      .catch((error) => {
        console.error("Error al volver participante a usuario:", error);
      });
  };

  const handleDocumentoChange = (fieldName, value) => {
    setSelectedOption(value);
    handleInputChange(fieldName, value);
  };

  const updateParticipante = () => {
    const dataToSend = {}; // Create an empty object to hold the data to send
    Object.entries(modifiedFields).forEach(([fieldName, modified]) => {
      // Only add fields that are modified and not empty
      if (modified && formData[fieldName]) {
        dataToSend[fieldName] = formData[fieldName];
      }
    });

    Axios.put(
      `https://appexploratarapaca.com/sdbmg/participantes/${participante_id}`,
      dataToSend
    )
      .then(() => {
        setSuccessMessage("¡El participante se modificó correctamente!");
      })
      .catch((error) => {
        console.error("Error al modificar participante:", error);
      });
  };

  return (
    <div className="container main-body pb-3 my-5">
      <h1>Informacion Participante</h1>
      {data.length > 0 && (
        <div className="container-body">
          <div className="modal-body">
            <div className="row gy-3 row-column-2 mb-3">
              <div className="col-6">
                <label className="form-label">Primer Nombre</label>
                <input
                  type="text"
                  className={`form-control ${errors.nombre && "is-invalid"}`}
                  value={formData.nombre}
                  placeholder={data[0].nombre}
                  onChange={(e) => handleInputChange("nombre", e.target.value)}
                />
                {errors.nombre && (
                  <div className="invalid-feedback">{errors.nombre}</div>
                )}
              </div>
              <div className="col-6">
                <label className="form-label">Segundo Nombre</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.seg_nombre && "is-invalid"
                  }`}
                  value={formData.seg_nombre}
                  placeholder={data[0].seg_nombre}
                  onChange={(e) =>
                    handleInputChange("seg_nombre", e.target.value)
                  }
                />
                {errors.ApellidoP && (
                  <div className="invalid-feedback">{errors.ApellidoP}</div>
                )}
              </div>
            </div>
            <div className="row gy-3 mb-3">
              <div className="col-6">
                <label className="form-label">Apellido Paterno</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.apellido_p && "is-invalid"
                  }`}
                  value={formData.apellido_p}
                  placeholder={data[0].apellido_p}
                  onChange={(e) =>
                    handleInputChange("apellido_p", e.target.value)
                  }
                />
                {errors.apellido_p && (
                  <div className="invalid-feedback">{errors.apellido_p}</div>
                )}
              </div>
              <div className="col-6">
                <label className="form-label">Apellido Materno</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.apellido_m && "is-invalid"
                  }`}
                  value={formData.apellido_m}
                  placeholder={data[0].apellido_m}
                  onChange={(e) =>
                    handleInputChange("apellido_m", e.target.value)
                  }
                />
                {errors.apellido_m && (
                  <div className="invalid-feedback">{errors.apellido_m}</div>
                )}
              </div>
            </div>
            <div className="row gy-3 mb-3">
              <div className="col-6">
                <label className="form-label">Año de Nacimiento</label>
                <input
                  type="number"
                  min="1900"
                  max="2099"
                  step="1"
                  maxlength="4"
                  className="form-control"
                  value={formData.ano_nacimiento}
                  placeholder={data[0].ano_nacimiento}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value);
                    if (newValue <= 2099 || e.target.value == "") {
                      handleInputChange("ano_nacimiento", e.target.value);
                    } else {
                      setFormData({
                        ...formData,
                        ano_nacimiento: 2000,
                      });
                    }
                  }}
                />
                {errors.apellido_p && (
                  <div className="invalid-feedback">{errors.apellido_p}</div>
                )}
              </div>
              <div className="col-6">
                <label className="form-label">Sexo</label>
                <select
                  className={`form-control ${
                    errors.apellido_m && "is-invalid"
                  }`}
                  value={formData.sexo}
                  placeholder={data[0].sexo}
                  onChange={(e) => handleInputChange("sexo", e.target.value)}
                >
                  <option>Seleccione Opcion</option>
                  <option value={"Hombre"}>Hombre</option>
                  <option value={"Mujer"}>Mujer</option>
                  <option value={"Otro"}>Otro</option>
                  <option value={"Prefiere no decirlo"}>
                    Prefiere no decirlo
                  </option>
                  <option value={"Sin información"}>Sin información</option>
                </select>
                {errors.apellido_m && (
                  <div className="invalid-feedback">{errors.apellido_m}</div>
                )}
              </div>
            </div>
            <div className="row gy-3 mb-3">
              <div className="col-6">
                <label className="form-label">Especialidad</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.especialidad}
                  placeholder={data[0].especialidad}
                  onChange={(e) =>
                    handleInputChange("especialidad", e.target.value)
                  }
                />
                {errors.apellido_p && (
                  <div className="invalid-feedback">{errors.apellido_p}</div>
                )}
              </div>
              <div className="col-6">
                <label className="form-label">Establecimiento</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.establecimiento && "is-invalid"
                  }`}
                  value={formData.establecimiento}
                  placeholder={data[0].establecimiento}
                  onChange={(e) =>
                    handleInputChange("establecimiento", e.target.value)
                  }
                />
              </div>
            </div>

            <div className="row gy-3 mb-3">
              <div className="col-6">
                <label className="form-label">Teléfono</label>
                <div className="input-group">
                  <span className="input-group-text">(+56)</span>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.telefono && "is-invalid"
                    }`}
                    value={formData.telefono || ""}
                    placeholder={data[0].telefono}
                    onChange={(e) =>
                      handleInputChange("telefono", e.target.value)
                    }
                  />
                  {errors.telefono && (
                    <div className="invalid-feedback">{errors.telefono}</div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <label className="form-label">Correo Electrónico</label>
                <input
                  type="text"
                  className={`form-control ${errors.email && "is-invalid"}`}
                  value={formData.email}
                  placeholder={data[0].email}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email}</div>
                )}
              </div>
            </div>
            <div className="row gy-3 mb-3">
              <div className="col-6 col-auto d-flex flex-column">
                <label className="form-check-label">RBD</label>
                <div className="flex-fill d-flex align-items-center justify-content-center">
                  <input
                    type="text"
                    className="form-control"
                    value={formData.rbd}
                    placeholder={data[0].rbd}
                    onChange={(e) => handleInputChange("rbd", e.target.value)}
                  />
                </div>
              </div>
            </div>
            {Object.keys(errors).length > 0 && (
              <div className="alert alert-danger" role="alert">
                Para volver el participante a docente, se requiere correo
                electrónico.
              </div>
            )}
            {successMessage && ( // Mostrar el mensaje de éxito si existe
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}
          </div>
          <div className="modal-footer  gap-2">
            {auth.roles == "admin" && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleToUser}
              >
                Convertir a Usuario
              </button>
            )}

            <button
              type="button"
              className="btn btn-primary"
              onClick={updateParticipante}
            >
              Guardar Cambios
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleDelete}
            >
              Eliminar
            </button>
          </div>
        </div>
      )}
      <h2 className="my-3">Familiares de Participante</h2>
      <TablaFamiliares id={participante_id} />
    </div>
  );
}
