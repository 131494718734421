import { ModalAddActividad } from "../Modals/ModalActividad";
import { ModalUpdateActividad } from "../Modals/ModalActividadUpdate";
import { ModalAddDocente } from "../Modals/ModalDocente";
import { ModalUpdateDocente } from "../Modals/ModalDocenteUpdate";
import { ModalAddParticipante } from "../Modals/ModalParticipante";
import { ModalContactoRegistro } from "../Modals/ModalSolicitudRegistro";
import { ModalAddFamiliar } from "../Modals/ModalAddFamiliar";
import { ModalSeguridad } from "../Modals/ModalSeguridad";

export function ParticipanteButton() {
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#modalAddParticipante"
      >
        Agregar Participante
      </button>
      <ModalAddParticipante />
    </div>
  );
}

export function DocenteButton() {
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#addDocenteModal"
      >
        Agregar Docente
      </button>
      <ModalAddDocente />
    </div>
  );
}

export function ActividadButton() {
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#ModalActividad"
      >
        Agregar Actividad
      </button>
      <ModalAddActividad />
    </div>
  );
}

export function UpdateActividadButton({ actividadId }) {
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#ModalUpdateActividad"
      >
        Modificar Actividad
      </button>
      <ModalUpdateActividad actividadId={actividadId} />
    </div>
  );
}

export function ButtonContactoRegistro() {
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#ModalContactoRegistro"
      >
        Agregar Contacto de Registro
      </button>
      <ModalContactoRegistro />
    </div>
  );
}
export function ButtonSeguridad(msgBtn, msgMdl) {
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#ModalSeguridad"
      >
        ${msgBtn}
      </button>
      <ModalSeguridad msg={msgMdl} />
    </div>
  );
}
export function ButtonAddFamiliar({ participante_id }) {
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#ModalAddFamiliar"
      >
        Nuevo Familiar
      </button>
      <ModalAddFamiliar participante_id={participante_id} />
    </div>
  );
}
