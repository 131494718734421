import { useState, useEffect } from "react";
import "./modal.css";
import Axios from "axios";
import useAuth from "../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
export function ModalUpdateActividad({ actividadId }) {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [data, setData] = useState({});
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [modifiedFields, setModifiedFields] = useState({});
  const location = useLocation();
  const from = location.state?.from?.pathname || "/actividades";
  useEffect(() => {
    const fetchData = async () => {
      try {
        const docentesResponse = await fetch(
          `https://appexploratarapaca.com/sdbmg/actividades/ById/${actividadId}`
        );
        const docentesData = await docentesResponse.json();

        setData(docentesData);

        // Initialize formData with fetched data
        setFormData({
          nombre: docentesData.nombre || "", // Providing default value if docentesData.nombre is undefined
          descripcion: docentesData.descripcion || "",
          estado: docentesData.estado || "",
          fecha_evento: docentesData.fecha_evento || "",
          codigo_proyecto: docentesData.codigo_proyecto || "",
          imagen: docentesData.codigo_proyecto || "",
          permiso: docentesData.codigo_proyecto || "",
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [actividadId]);
  // Nuevo estado para el mensaje de éxito

  useEffect(() => {
    setModifiedFields({});
  }, [data]);

  const handleInputChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
    setModifiedFields((prevModifiedFields) => ({
      ...prevModifiedFields,
      [fieldName]: value !== data[fieldName], // Check if the value is different from the original data
    }));
  };
  const handleDelete = (id) => {
    Axios.delete(
      `https://appexploratarapaca.com/sdbmg/actividades/${actividadId}`
    )
      .then(() => {
        console.log("éxito");
        setSuccessMessage("¡La actividad se Elimino correctamente!");
        setTimeout(function () {
          window.location.replace("https://appexploratarapaca.com/actividades");
        }, 2000);
      })
      .catch((error) => {
        console.error("Error al eliminar actividad:", error);
      });
  };

  const handleFileInputChange = (fieldName, event) => {
    const file = event.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: file,
    }));
    setModifiedFields((prevModifiedFields) => ({
      ...prevModifiedFields,
      [fieldName]: true,
    }));
  };

  const updateActividad = () => {
    const formDataToSend = new FormData();
    Object.entries(modifiedFields).forEach(([fieldName, modified]) => {
      if (modified) {
        formDataToSend.append(fieldName, formData[fieldName]);
      } else {
        // If the field is not modified, append the original data
        formDataToSend.append(fieldName, data[fieldName]);
      }
    });
    formDataToSend.append("creado_por", auth.usuario_id);

    Axios.put(
      `https://appexploratarapaca.com/sdbmg/actividades/${actividadId}`,
      formDataToSend
    )
      .then(() => {
        console.log("éxito");
        setSuccessMessage("¡La actividad se modificó correctamente!");
        setTimeout(function () {
          window.location.replace("https://appexploratarapaca.com/actividades");
        }, 2000);
      })
      .catch((error) => {
        console.error("Error al modificar actividad:", error);
      });
  };
  if (!Object.keys(data).length) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className="modal fade"
      id="ModalUpdateActividad"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog" id="modalWindowDocente">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Modificar Actividad
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <a href="../actividades" />
            </button>
          </div>
          <div className="modal-body">
            <div className="row gy-3 row-column-2 mb-3">
              <div className="col-6">
                <label className="form-label">Nombre de Actividad</label>
                <input
                  type="text"
                  className={`form-control ${errors.nombre && "is-invalid"}`}
                  placeholder={`${data[0].nombre}`}
                  value={formData.nombre}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Validate the input using regular expression
                    if (
                      /^[A-Za-z\s'-]+$/.test(inputValue) ||
                      inputValue === ""
                    ) {
                      handleInputChange("nombre", e.target.value);
                    }
                  }}
                />
                {errors.nombre && (
                  <div className="invalid-feedback">{errors.nombre}</div>
                )}
              </div>
              <div className="col-6">
                <label className="form-label">Fecha de Evento</label>
                <input
                  type="date"
                  className={`form-control ${
                    errors.fecha_evento && "is-invalid"
                  }`}
                  placeholder={`${data[0].fecha_evento}`}
                  value={formData.fecha_evento}
                  onChange={(e) =>
                    handleInputChange("fecha_evento", e.target.value)
                  }
                />
                {errors.fecha_evento && (
                  <div className="invalid-feedback">{errors.fecha_evento}</div>
                )}
              </div>
            </div>
            <div className="row gy-3 mb-3">
              <div className="col-6">
                <label className="form-label">Descripción</label>
                <input
                  type="text"
                  placeholder={`${data[0].descripcion}`}
                  className={`form-control ${
                    errors.descripcion && "is-invalid"
                  }`}
                  value={formData.descripcion}
                  onChange={(e) =>
                    handleInputChange("descripcion", e.target.value)
                  }
                />
                {errors.descripcion && (
                  <div className="invalid-feedback">{errors.descripcion}</div>
                )}
              </div>
              <div className="col-6">
                <label className="form-label">Estado de Actividad</label>
                <select
                  placeholder={`${data[0].estado}`}
                  id="optionsSelect"
                  className="form-select"
                  value={formData.estado}
                  onChange={(e) => handleInputChange("estado", e.target.value)}
                >
                  <option value="Inscripción Habilitada">
                    Inscripción Habilitada
                  </option>
                  <option value="Inscripción Deshabilitada">
                    Inscripción Deshabilitada
                  </option>
                  <option value="Actividad Finalizada">
                    Actividad Finalizada
                  </option>
                </select>
              </div>
            </div>
            <div className="row gy-3 mb-3">
              <div className="col-6">
                <label className="form-label">Agregar imagen</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileInputChange("imagen", e)}
                />
                {errors.imagen && (
                  <div className="invalid-feedback">{errors.imagen}</div>
                )}
              </div>
              <div className="col-6">
                <label className="form-label">
                  Agregar permiso de cesión de imagen
                </label>
                <input
                  type="file"
                  accept=".pdf"
                  onChange={(e) => handleFileInputChange("permiso", e)}
                />
                {errors.permiso && (
                  <div className="invalid-feedback">{errors.permiso}</div>
                )}
              </div>
            </div>
            <div className="row gy-3 mb-3">
              <div className="col-6">
                <label className="form-label">Codigo de Proyecto</label>
                <input
                  placeholder={`${data[0].codigo_proyecto}`}
                  type="text"
                  className={`form-control ${
                    errors.codigo_proyecto && "is-invalid"
                  }`}
                  value={formData.codigo_proyecto}
                  onChange={(e) =>
                    handleInputChange("codigo_proyecto", e.target.value)
                  }
                />
                {errors.codigo_proyecto && (
                  <div className="invalid-feedback">
                    {errors.codigo_proyecto}
                  </div>
                )}
              </div>
            </div>
            {successMessage && ( // Mostrar el mensaje de éxito si existe
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={updateActividad}
            >
              Guardar Cambios
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleDelete}
            >
              Eliminar
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
