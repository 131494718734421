import "./table.css";
import { useEffect, useState } from "react";

export function TableDocenteActividad({ actividadId }) {
  const [docentes, setDocentes] = useState([]);
  const [permisos, setPermisos] = useState([]);
  const [selectedDocentes, setSelectedDocentes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 15;
  const totalPages = Math.ceil(docentes.length / itemsPerPage);
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener datos de los docentes
        const responseDocentes = await fetch(
          "https://appexploratarapaca.com/sdbmg/usuarios/doc&"
        );
        const dataDocentes = await responseDocentes.json();
        setDocentes(dataDocentes);

        // Obtener datos de permisos basados en la actividadId
        const responsePermisos = await fetch(
          `https://appexploratarapaca.com/sdbmg/permisos/${actividadId}`
        );
        const dataPermisos = await responsePermisos.json();
        setPermisos(dataPermisos);

        // Obtener los IDs de los docentes de los permisos y establecerlos como selectedDocentes
        const docenteIdsEnPermisos = dataPermisos.map(
          (permiso) => permiso.docente_id
        );
        setSelectedDocentes(docenteIdsEnPermisos);
      } catch (error) {
        console.error("Error al obtener los datos", error);
      }
    };

    fetchData();
  }, [actividadId]);

  const handleCheckboxChange = (docenteId) => {
    const isChecked = selectedDocentes.includes(docenteId);

    if (isChecked) {
      setSelectedDocentes((prevSelected) =>
        prevSelected.filter((id) => id !== docenteId)
      );
    } else {
      setSelectedDocentes((prevSelected) => [...prevSelected, docenteId]);
    }
  };

  const handleSave = async () => {
    setIsLoading(true);

    try {
      // Obtener los IDs de los docentes que están actualmente en permisos
      const docenteIdsEnPermisos = permisos.map(
        (permiso) => permiso.docente_id
      );

      // Eliminar filas de permiso_actividad donde docenteId no está en selectedDocentes
      const deletedRows = docenteIdsEnPermisos
        .filter((docenteId) => !selectedDocentes.includes(docenteId))
        .map(async (docenteId) => {
          const permisoAEliminar = permisos.find(
            (permiso) => permiso.docente_id === docenteId
          );
          console.log(permisoAEliminar);
          if (permisoAEliminar) {
            await fetch(
              `https://appexploratarapaca.com/sdbmg/permisos/${permisoAEliminar.permiso_id}`,
              {
                method: "DELETE",
              }
            );
          }
        });

      // Añadir filas a permiso_actividad donde docenteId está en selectedDocentes
      const addedRows = selectedDocentes
        .filter((docenteId) => !docenteIdsEnPermisos.includes(docenteId))
        .map(async (docenteId) => {
          await fetch("https://appexploratarapaca.com/sdbmg/permisos", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              docente_id: docenteId,
              actividad_id: actividadId,
            }),
          });
        });

      // Esperar a que todas las solicitudes de DELETE y POST se completen
      await Promise.all([...deletedRows, ...addedRows]);

      // Obtener datos de permisos actualizados
      const updatedPermisosResponse = await fetch(
        `https://appexploratarapaca.com/sdbmg/permisos/${actividadId}`
      );
      const updatedPermisosData = await updatedPermisosResponse.json();
      setPermisos(updatedPermisosData);
    } catch (error) {
      console.error("Error al actualizar permiso_actividad", error);
    }finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const renderRows = () => {
    const filteredDocentes = docentes.filter((docente) => {
      const nombre = docente.nombre ? docente.nombre.toLowerCase() : "";
      const apellido_p = docente.apellido_p
        ? docente.apellido_p.toLowerCase()
        : "";
      const apellido_m = docente.apellido_m
        ? docente.apellido_m.toLowerCase()
        : "";
      const establecimiento = docente.establecimiento
        ? docente.establecimiento.toLowerCase()
        : "";

      return (
        nombre.includes(searchQuery.toLowerCase()) ||
        apellido_p.includes(searchQuery.toLowerCase()) ||
        apellido_m.includes(searchQuery.toLowerCase()) ||
        establecimiento.includes(searchQuery.toLowerCase())
      );
    });

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return filteredDocentes.slice(startIndex, endIndex).map((docente) => (
      <tr key={docente.user_id}>
        <td>{docente.nombre}</td>

        <td>
          {docente.apellido_p}, {docente.apellido_m}
        </td>
        <td>{docente.establecimiento}</td>
        <td>{docente.telefono}</td>
        <td className="d-flex justify-content-center">
          <input
            type="checkbox"
            checked={selectedDocentes.includes(docente.user_id)}
            onChange={() => handleCheckboxChange(docente.user_id)}
          />
        </td>
      </tr>
    ));
  };

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }
    return (
      <nav aria-label="Page navigation example ">
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <span aria-hidden="true">&laquo;</span>
            </button>
          </li>
          {pageNumbers}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <span aria-hidden="true">&raquo;</span>
            </button>
          </li>
        </ul>
      </nav>
    );
  };

  return (
    <div className="rounded tb-container" style={{ margin: "20px" }}>
      <div className="search-container">
        <input
          className="search-input"
          type="text"
          placeholder="Ingrese Nombre, Apellido o Establecimiento para buscar docente..."
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
      <table className="caption-top table rounded table-sm table-bordered table-hover table-striped">
        <caption>Lista de Docentes</caption>
        <thead className="table-primary">
          <tr>
            <th className="text-center">Nombre</th>
            <th className="text-center">Apellidos</th>
            <th className="text-center">Establecimiento</th>
            <th className="text-center">Teléfono</th>
            <th className="text-center">Añadir a Actividad</th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
      <div className="d-flex justify-content-between align-items-start">
        <div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSave}
            disabled={isLoading}
          >
            {isLoading ? 'Guardando...' : 'Guardar Cambios'}
          </button>
        </div>
        <div className="d-flex  align-items-end">
          <div className="d-flex flex-grow-1 justify-content-between align-items-center mx-1">
            {renderPagination()}
          </div>
        </div>
      </div>
    </div>
  );
}
