import "./table.css";
import { useEffect, useState } from "react";

export function TablaParticipanteDocente({ actividadId, id }) {
  const [participantes, setParticipantes] = useState([]);
  const [familiares, setFamiliares] = useState([]);
  const [asistencia, setAsistencia] = useState([]);
  const [selectedParticipantes, setSelectedParticipantes] = useState([]);
  const [selectedFamiliares, setSelectedFamiliares] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 15;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data for participantes
        const participantesResponse = await fetch(
          `https://appexploratarapaca.com/sdbmg/asistencia/participante/${id}`
        );
        const participantesData = await participantesResponse.json();
        setParticipantes(participantesData);

        // Fetch data for asistencia based on actividadId
        const asistenciaResponse = await fetch(
          `https://appexploratarapaca.com/sdbmg/asistencia/${actividadId}`
        );
        const asistenciaData = await asistenciaResponse.json();
        setAsistencia(asistenciaData);

        // Get participante IDs from asistencia and set as selectedParticipantes
        const participanteInAsistencia = asistenciaData.map(
          (asistencia) => asistencia.participante_id
        );
        setSelectedParticipantes(participanteInAsistencia);

        //Obtener Familiar_id dentro de asistencia
        const familiaresInAsistencia = asistenciaData.map(
          (asistencia) => asistencia.familiar_id
        );

        //Fetch Data para familiares
        const familiaresResponse = await fetch(
          `https://appexploratarapaca.com/sdbmg/familiares/`
        );
        const familiaresData = await familiaresResponse.json();
        setFamiliares(familiaresData);

        const groupedFamiliares = familiaresData.reduce((acc, familiar) => {
          acc[familiar.participante_id] = acc[familiar.participante_id] || [];
          acc[familiar.participante_id].push(familiar);
          return acc;
        }, {});

        setFamiliares(groupedFamiliares);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [actividadId]);

  const handleCheckboxChange = (participanteId) => {
    const isChecked = selectedParticipantes.includes(participanteId);

    if (isChecked) {
      setSelectedParticipantes((prevSelected) =>
        prevSelected.filter((id) => id !== participanteId)
      );
    } else {
      setSelectedParticipantes((prevSelected) => [
        ...prevSelected,
        participanteId,
      ]);
    }
  };

  const handleSelectFamiliar = async (participanteId, familiarId) => {
    try {
      // Verificar si el participante está marcado en la asistencia
      const isParticipanteChecked =
        selectedParticipantes.includes(participanteId);

      // Obtener la asistencia actual del participante
      const asistenciaDelParticipante = asistencia.find(
        (asistencia) => asistencia.participante_id === participanteId
      );

      // Si el participante está marcado en la asistencia
      if (isParticipanteChecked && asistenciaDelParticipante) {
        // Eliminar la fila de asistencia actual
        await fetch(
          `https://appexploratarapaca.com/sdbmg/asistencia/${asistenciaDelParticipante.asistencia_id}`,
          {
            method: "DELETE",
          }
        );
      }

      // Añadir una nueva fila de asistencia con el familiar seleccionado
      await fetch("https://appexploratarapaca.com/sdbmg/asistencia", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          participante_id: participanteId,
          actividad_id: actividadId,
          familiar_id: familiarId,
        }),
      });

      // Actualizar el estado de la asistencia para reflejar los cambios
      const updatedAsistenciaResponse = await fetch(
        `https://appexploratarapaca.com/sdbmg/asistencia/${actividadId}`
      );
      const updatedAsistenciaData = await updatedAsistenciaResponse.json();
      setAsistencia(updatedAsistenciaData);

      // Actualizar el estado de los familiares seleccionados
      setSelectedFamiliares((prevSelected) => ({
        ...prevSelected,
        [participanteId]: familiarId,
      }));
    } catch (error) {
      console.error("Error al seleccionar el familiar:", error);
    }
  };

  const handleSave = async () => {
    try {
      // Get participante IDs that are currently in asistencia
      const participanteInAsistencia = asistencia.map(
        (asistencia) => asistencia.participante_id
      );

      // Delete rows from asistencia where participanteId is not in selectedParticipantes
      const deletedRows = participanteInAsistencia
        .filter(
          (participanteId) => !selectedParticipantes.includes(participanteId)
        )
        .map(async (participanteId) => {
          const asistenciaToDelete = asistencia.find(
            (asistencia) => asistencia.participante_id === participanteId
          );

          if (asistenciaToDelete) {
            await fetch(
              `https://appexploratarapaca.com/sdbmg/asistencia/${asistenciaToDelete.asistencia_id}`,
              {
                method: "DELETE",
              }
            );
          }
        });

      // Add rows to asistencia where participanteId is in selectedParticipantes
      const addedRows = selectedParticipantes.map(async (participanteId) => {
        await handleSelectFamiliar(
          participanteId,
          selectedFamiliares[participanteId] || ""
        );
      });

      // Wait for all DELETE and POST requests to complete
      await Promise.all([...deletedRows, ...addedRows]);

      // Fetch updated asistencia data
      const updatedAsistenciaResponse = await fetch(
        `https://appexploratarapaca.com/sdbmg/asistencia/${actividadId}`
      );
      const updatedAsistenciaData = await updatedAsistenciaResponse.json();
      setAsistencia(updatedAsistenciaData);
      window.location.reload();
    } catch (error) {
      console.error("Error updating asistencia", error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const renderRows = () => {
    const filteredParticipantes = participantes.filter(
      (participante) =>
        participante.apellido_p
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        participante.nombre.toLowerCase().includes(searchQuery.toLowerCase()) ||
        participante.dni.toLowerCase().includes(searchQuery.toLowerCase()) ||
        participante.rut
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) /* Add more fields as needed */
    );

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return filteredParticipantes
      .slice(startIndex, endIndex)
      .map((participante) => (
        <tr key={participante.participante_id}>
          <td>
            {participante.apellido_p}, {participante.apellido_m}
          </td>
          <td>{participante.nombre}</td>
          <td>{participante.ciclo}</td>
          <td>{participante.curso}</td>
          <td>
            {participante.dni ||
              `${participante.rut}-${participante.dig_verificador}`}
          </td>
          <td>
            <input
              type="checkbox"
              checked={selectedParticipantes.includes(
                participante.participante_id
              )}
              onChange={() =>
                handleCheckboxChange(participante.participante_id)
              }
            />
          </td>
          <td>
            <select
              onChange={(e) => {
                handleSelectFamiliar(
                  participante.participante_id,
                  e.target.value
                );
              }}
              value={selectedFamiliares[participante.participante_id] || ""}
            >
              <option value={""}>Seleccione</option>
              {familiares[participante.participante_id] &&
                familiares[participante.participante_id].map(
                  (familiar, familiarIndex) => (
                    <option key={familiarIndex} value={familiar.familiar_id}>
                      {familiar.nombre} {familiar.apellido_p}
                    </option>
                  )
                )}
            </select>
          </td>
        </tr>
      ));
  };

  const totalPages = Math.ceil(participantes.length / itemsPerPage);

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }

    return (
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            {/* <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button> */}
            <span aria-hidden="true">&laquo;</span>
          </li>
          {pageNumbers}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <span aria-hidden="true">&raquo;</span>
            {/* <button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button> */}
          </li>
        </ul>
      </nav>
    );
  };

  return (
    <div className="rounded tb-container" style={{ margin: "20px" }}>
      <div className="search-container">
        <input
          className="search-input"
          type="text"
          placeholder="Ingrese Nombre, Apellido, Rut, etc ..."
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
      <table className="caption-top table rounded table-sm table-bordered table-hover table-striped">
        <caption>Lista de Docentes</caption>
        <thead className="table-primary">
          <tr>
            <th>Apellidos</th>
            <th>Nombre</th>
            <th>Ciclo</th>
            <th>Curso</th>
            <th>Rut o DNI</th>
            <th>Asistencia</th>
            <th>Familiar</th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
      <div className="d-flex justify-content-between align-items-start">
        <div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSave}
          >
            Enviar Lista
          </button>
        </div>
        <div className="d-flex align-items-center mx-1">
          {renderPagination()}
        </div>
      </div>
    </div>
  );
}
