import useAuth from "../../hooks/useAuth";
import "../Navbar/navbar.css";
import { LogoutLink } from "./logout";
export function Navbar() {
  const { auth } = useAuth();

  return (
    <nav
      className="navbar navbar-dark sticky-top navbar-expand-lg bg-primary mb-2"
      data-bs-theme="dark"
    >
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center w-100">
          {/* Sección izquierda */}
          <div className="sec-izq ">
            <ul className="navbar-nav gap-2 mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link d-flex flex-row" href="../actividades">
                  <span className="d-flex fs-5 fw-bold align-self-center">
                    Actividades
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="../participantes">
                  <span className="d-flex fs-5 fw-bold align-self-center">
                    Participantes
                  </span>
                </a>
              </li>
              {auth.roles === "admin" && ( // Mostrar solo para el rol de administrador
                <li className="nav-item">
                  <a className="nav-link" href="../docentes">
                    <span className="d-flex fs-5 fw-bold align-self-center">
                      Docentes
                    </span>
                  </a>
                </li>
              )}
            </ul>
          </div>

          {/* Logo en el centro */}
          <a
            className="navbar-brand flex-grow-1 p-0 d-flex m-0 flex-column align-items-center fs-5"
            href="#"
          >
            <img
              className="align-self-center"
              width={"45vw"}
              height={"45vh"}
              src={process.env.PUBLIC_URL + "/images/logo.png"}
              alt="Logo de Explora"
            />
            <div className="m-2 d-none d-sm-inline">Explora Tarapacá</div>
          </a>

          {/* Sección derecha */}
          <div className=" sec-der ">
            <ul className="navbar-nav d-flex justify-content-end mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link d-flex flex-row" href="../userinfo">
                  <span className="d-flex fw-normal align-self-center">
                    {auth.nombre_completo}
                  </span>
                </a>
              </li>

              <li className="nav-item me-2 d-lg-block">
                <span className="nav-link user-select-none d-flex justify-center fw-bold">
                  |
                </span>
              </li>
              <li className="nav-item d-block me-2 d-lg-block">
                <a className="nav-link">
                  <span className="fw-normal d-flex justify-center">
                    <LogoutLink />
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
