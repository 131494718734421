import "./table.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export function TableActividadDocente({ id, admin }) {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;

        //response = await fetch(`https://appexploratarapaca.com/sdbmg/docentes/${id}`);
        response = await fetch(
          `https://appexploratarapaca.com/sdbmg/actividades/${id}`
        );

        if (!response.ok) {
          throw new Error(`Error al obtener los datos: ${response.status}`);
        }

        const jsonData = await response.json();

        // Comprueba si jsonData es un array
        if (Array.isArray(jsonData)) {
          setData(jsonData);
        } else {
          // Si jsonData no es un array, manejarlo en consecuencia
          console.error("Los datos recibidos no son un array:", jsonData);
        }
      } catch (error) {
        console.error("Error al obtener los datos", error);
      }
    };

    fetchData();
  }, [admin, id]);

  const handleRowClick = (actividad_id) => {
    // Navegar a la URL especificada cuando se hace clic en una fila
    navigate(`/actividades/${actividad_id}`);
  };

  return (
    <div className="rounded tb-container" style={{ margin: "20px" }}>
      <div className="search-container">
        <input
          className="search-input"
          type="text"
          placeholder="Ingrese Nombre descripcion o Estado para buscar actividad..."
        />
      </div>
      <table className="caption-top table rounded table-sm table-bordered table-hover table-striped">
        <caption>Lista de Actividades</caption>
        <thead className="table-primary">
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Fecha</th>
            <th scope="col">Descripción</th>
            <th scope="col">Estado</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr
              key={item.actividad_id}
              onClick={() => handleRowClick(item.actividad_id)}
              style={{ cursor: "pointer" }}
            >
              <td>{item.nombre}</td>
              <td>
                {item.fecha_evento &&
                  // Formatear la fecha si fecha_evento está disponible
                  new Date(item.fecha_evento).toLocaleDateString("es-ES", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
              </td>
              <td>{item.descripcion}</td>
              <td>{item.estado}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-flex justify-content-end align-items-end">
        <div className="d-flex align-items-center mx-1">
          <nav aria-label="Navegación de páginas">
            <ul className="pagination">
              <li className="page-item">
                <a className="page-link" href="#">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              {/* <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li> */}
              <li className="page-item">
                <a className="page-link" href="#">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
