import { useState, useEffect } from "react";
import { DocenteButton } from "../Buttons/ButtonModals";
import { ModalUpdateDocente } from "../Modals/ModalDocenteUpdate";
import "./table.css";
import { useNavigate } from "react-router-dom";

export function TableDocente() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const [selectedDocenteId, setSelectedDocenteId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://appexploratarapaca.com/sdbmg/usuarios"
        );
        const jsonData = await response.json();
        setData(jsonData);
        setFilteredData(jsonData);
      } catch (error) {
        console.error("Error recopilando datos", error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = data.filter((item) => {
      const nombre = item.nombre ? item.nombre.toLowerCase() : "";
      const apellido_p = item.apellido_p ? item.apellido_p.toLowerCase() : "";
      const apellido_m = item.apellido_m ? item.apellido_m.toLowerCase() : "";
      const telefono = item.telefono ? item.telefono.toLowerCase() : "";
      const email = item.email ? item.email.toLowerCase() : "";
      const establecimiento = item.establecimiento
        ? item.establecimiento.toLowerCase()
        : "";

      return (
        nombre.toLowerCase().includes(query) ||
        apellido_p.toLowerCase().includes(query) ||
        apellido_m.toLowerCase().includes(query) ||
        establecimiento.toLowerCase().includes(query) ||
        telefono.toLowerCase().includes(query) ||
        email.toLowerCase().includes(query)
      );
    });
    setFilteredData(filtered);
  };

  const handleRowClick = (user_id) => {
    // Navegar a la URL especificada cuando se hace clic en una fila
    navigate(`/usuarios/${user_id}`);
  };

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="rounded tb-container" style={{ margin: "20px" }}>
      <div className="search-container">
        <input
          className="search-input"
          type="text"
          placeholder="Ingrese Nombre, Apellido, Establecimiento, Correo, Teléfono para buscar actividad..."
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
      <table className="caption-top table rounded table-sm table-bordered table-hover table-striped">
        <caption>Lista de Docentes</caption>
        <thead className="table-primary">
          <tr>
            <th scope="col">Apellidos</th>
            <th scope="col">Nombre</th>
            <th scope="col">Rut o DNI</th>
            <th scope="col">Teléfono</th>
            <th scope="col">Correo Electrónico</th>
            <th scope="col">Establecimiento</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item) => (
            <tr
              key={item.user_id}
              onClick={() => handleRowClick(item.user_id)}
              style={{ cursor: "pointer" }}
            >
              <td>
                {item.apellido_p}, {item.apellido_m}
              </td>
              <td>{item.nombre}</td>
              <td>{item.dni || `${item.rut}-${item.dig_verificador}`}</td>
              <td>{item.telefono}</td>
              <td>{item.email}</td>
              <td>{item.establecimiento}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-flex align-items-start">
        <div className="d-flex flex-grow-1 justify-content-between align-items-center mx-1">
          {/* <DocenteButton /> */}
          <nav aria-label="Page navigation example ">
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  &laquo;
                </button>
              </li>
              {[...Array(totalPages).keys()].map((pageNumber) => (
                <li
                  key={pageNumber + 1}
                  className={`page-item ${
                    currentPage === pageNumber + 1 ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(pageNumber + 1)}
                  >
                    {pageNumber + 1}
                  </button>
                </li>
              ))}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  &raquo;
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
