import { useState } from "react";
import "./modal.css";
import Axios from "axios";
import useAuth from "../../hooks/useAuth";
const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
export function ModalAddFamiliar({ participante_id }) {
  const { auth } = useAuth();
  const [selectedOption, setSelectedOption] = useState("");
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const [formData, setFormData] = useState({
    nombre: "",
    apellido_p: "",
    apellido_m: "",
    ano_nacimiento: 2010,
    sexo: "",
    documento_tipo: "1",
    rut: "",
    dig_verificador: "",
    dni: "",
    email: "",
    telefono: "",
  });
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.nombre.trim()) {
      newErrors.nombre = "Nombre es requerido";
      isValid = false;
    }
    if (!emailRegex.test(formData.email) && formData.email) {
      newErrors.email = "Please enter a valid email address";
      isValid = false;
    }

    if (!formData.apellido_p.trim()) {
      newErrors.apellido_p = "Apellido Paterno es requerido";
      isValid = false;
    }
    if (!formData.apellido_m.trim()) {
      newErrors.apellido_m = "Apellido Materno es requerido";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const addFamiliar = () => {
    if (selectedOption === "1") {
      setFormData({ ...formData, dni: "" });
    } else if (selectedOption === "0") {
      setFormData({ ...formData, rut: "" });
      setFormData({ ...formData, dig_verificador: "" });
    } else {
      setFormData({ ...formData, rut: "" });
      setFormData({ ...formData, dni: "" });

      setFormData({ ...formData, dig_verificador: "" });
    }

    if (!validateForm()) return;

    const formDataToSend = new FormData();
    formDataToSend.append("nombre", formData.nombre);
    formDataToSend.append("apellido_p", formData.apellido_p);
    formDataToSend.append("apellido_m", formData.apellido_m);
    formDataToSend.append("ano_nacimiento", formData.ano_nacimiento);
    formDataToSend.append("sexo", formData.sexo);
    formDataToSend.append("documento_tipo", formData.documento_tipo);
    formDataToSend.append("rut", formData.rut);
    formDataToSend.append("dig_verificador", formData.dig_verificador);
    formDataToSend.append("dni", formData.dni);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("telefono", formData.telefono);

    Axios.post(
      `https://appexploratarapaca.com/sdbmg/familiares/${participante_id}`,
      formDataToSend
    )
      .then(() => {
        console.log("Success");
        setSuccessMessage("¡La actividad se agregó correctamente!");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div
      className="modal fade"
      id="ModalAddFamiliar"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      {/* Modal content */}
      <div className="modal-dialog" id="modalWindowAddFamiliar">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Agregar Familiar
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row gy-3 row-column-2 mb-3">
              <div className="col-6">
                <label className="form-label">Nombre de Familiar(*)</label>
                <input
                  type="text"
                  className={`form-control ${errors.nombre && "is-invalid"}`}
                  value={formData.nombre}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Validate the input using regular expression
                    if (
                      /^[A-Za-z\s'-]+$/.test(inputValue) ||
                      inputValue === ""
                    ) {
                      setFormData({ ...formData, nombre: e.target.value });
                    }
                  }}
                />
                {errors.nombre && (
                  <div className="invalid-feedback">{errors.nombre}</div>
                )}
              </div>
              <div className="col-6">
                <label className="form-label">Apellido Paterno(*)</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.apellido_p && "is-invalid"
                  }`}
                  value={formData.apellido_p}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Validate the input using regular expression
                    if (
                      /^[A-Za-z\s'-]+$/.test(inputValue) ||
                      inputValue === ""
                    ) {
                      setFormData({ ...formData, apellido_p: e.target.value });
                    }
                  }}
                />
                {errors.apellido_p && (
                  <div className="invalid-feedback">{errors.apellido_p}</div>
                )}
              </div>
            </div>
            <div className="row gy-3 mb-3">
              <div className="col-6">
                <label className="form-label">Apellido Materno(*)</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.apellido_m && "is-invalid"
                  }`}
                  value={formData.apellido_m}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Validate the input using regular expression
                    if (
                      /^[A-Za-z\s'-]+$/.test(inputValue) ||
                      inputValue === ""
                    ) {
                      setFormData({ ...formData, apellido_m: e.target.value });
                    }
                  }}
                />
                {errors.apellido_m && (
                  <div className="invalid-feedback">{errors.apellido_m}</div>
                )}
              </div>
              <div className="col-6">
                <label className="form-label">Sexo</label>
                <select
                  id="sexoSelect"
                  value={formData.sexo}
                  onChange={(e) =>
                    setFormData({ ...formData, sexo: e.target.value })
                  }
                  className="form-select"
                >
                  <option value={"Hombre"}>Hombre</option>
                  <option value={"Mujer"}>Mujer</option>
                  <option value={"Otro"}>Otro</option>
                  <option value={"Prefiere no decirlo"}>
                    Prefiere no decirlo
                  </option>
                  <option value={"Sin información"}>Sin información</option>
                </select>
              </div>
            </div>
            <div className="row gy-3 mb-3">
              <div className="col-6">
                <label className="form-label">Año de Nacimiento(*)</label>
                <input
                  type="number"
                  min="1900"
                  max="2099"
                  step="1"
                  maxLength="4"
                  className="form-control"
                  value={formData.ano_nacimiento}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value);
                    if (newValue <= 2099 || e.target.value == "") {
                      setFormData({
                        ...formData,
                        ano_nacimiento: newValue,
                      });
                    } else {
                      setFormData({
                        ...formData,
                        ano_nacimiento: 2000,
                      });
                    }
                  }}
                />
              </div>
              <div className="col-6">
                <label className="form-label">Email</label>
                <input
                  type="text"
                  className={`form-control ${errors.email && "is-invalid"}`}
                  value={formData.email}
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value });
                  }}
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email}</div>
                )}
              </div>
            </div>
            <div className="row gy-3 mb-3">
              <div className="col-6">
                <label className="form-label">Telefono</label>
                <input
                  type="text"
                  className={`form-control ${errors.telefono && "is-invalid"}`}
                  value={formData.telefono}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Regular expression for validating telephone numbers
                    const telefonoPattern = /^[0-9+\-\s()]*$/;
                    // Check if the input is empty or matches the telephone pattern
                    if (inputValue === "" || telefonoPattern.test(inputValue)) {
                      // If valid or empty, update the form data
                      setFormData({ ...formData, telefono: inputValue });
                      // Reset the error state
                      setErrors({ ...errors, telefono: "" });
                    } else {
                      // If not valid, set an error
                      setErrors({
                        ...errors,
                        telefono: "Invalid telephone number",
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className="row gy-3 mb-3">
              <div className="col-6">
                <label className="form-label">
                  Tipo de Documento de Identificación
                </label>
                <select
                  id="optionsSelect"
                  className="form-select"
                  onChange={(e) => setSelectedOption(e.target.value)}
                >
                  <option value="" selected>
                    No conoce
                  </option>
                  <option value="1">RUT</option>
                  <option value="0">DNI</option>
                </select>
              </div>
              <div className="col-6">
                {selectedOption === "1" && (
                  <div className="option d-inline-flex row">
                    <div className="col d-flex flex-column justify-content-between">
                      <label className="form-label">RUT(*)</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.rut}
                        onChange={(e) =>
                          setFormData({ ...formData, rut: e.target.value })
                        }
                      />
                    </div>
                    <div className="col justify-content-center text-center">
                      <label className="form-label">
                        Digito Verificador(*)
                      </label>
                      <input
                        id="dig_verificador"
                        type="text"
                        className="form-control d-flex flex-shrink-1 flex-grow-0 mx-auto"
                        maxlength="1"
                        style={{ width: "50px", minWidth: "50px" }}
                        value={formData.dig_verificador}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            dig_verificador: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                )}
                {selectedOption === "0" && (
                  <div className="option d-inline-flex">
                    <div className="col">
                      <label className="form-label">DNI(*)</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.dni}
                        onChange={(e) =>
                          setFormData({ ...formData, dni: e.target.value })
                        }
                      />
                    </div>
                  </div>
                )}
                {selectedOption === "0" && (
                  <div className="option d-inline-flex">
                    <div className="col"></div>
                  </div>
                )}
              </div>
            </div>
            {successMessage && ( // Mostrar el mensaje de éxito si existe
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={addFamiliar}
            >
              Agregar
            </button>

            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
