import { useState, useEffect } from "react";
import Axios from "axios";
import useAuth from "../hooks/useAuth";

export function DatosUser() {
  const { auth } = useAuth();
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    contrasena: "",
    new_contrasena: "",
    confirm_new_contrasena: "",
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  console.log(auth.user_id);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfo = await fetch(
          `https://appexploratarapaca.com/sdbmg/usuarios/${auth.user_id}`
        );
        const userData = await userInfo.json();

        setData(userData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [auth.user_id]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let errors = {};

    if (!formData.contrasena.trim()) {
      errors.contrasena = "Por favor, ingresa tu contraseña actual.";
    }

    if (!formData.new_contrasena.trim()) {
      errors.new_contrasena = "Por favor, ingresa tu nueva contraseña.";
    }
    if (
      formData.new_contrasena.trim().length < 8 ||
      !formData.new_contrasena.match(/\d/)
    ) {
      errors.new_contrasena =
        "Contraseña debe tener un largo de 8 caracteres y almenos un numero.";
    }

    if (!formData.confirm_new_contrasena.trim()) {
      errors.confirm_new_contrasena =
        "Por favor, confirma tu nueva contraseña.";
    } else if (
      formData.new_contrasena.trim() !== formData.confirm_new_contrasena.trim()
    ) {
      errors.confirm_new_contrasena = "Las contraseñas no coinciden.";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await Axios.put(
          `https://appexploratarapaca.com/sdbmg/usuarios/user=${auth.user_id}`,
          {
            contrasena: formData.contrasena,
            new_contrasena: formData.new_contrasena,
          }
        );

        setSuccessMessage(response.data.message);
        setFormData({
          contrasena: "",
          new_contrasena: "",
          confirm_new_contrasena: "",
        });
        setErrors({});
      } catch (error) {
        console.error("Error al modificar contraseña:", error);
        setSuccessMessage("");
        setErrors({ contrasena: "La contraseña actual es incorrecta." });
      }
    }
  };

  return (
    <div className="container main-body pb-3 my-5">
      <h1>Datos de Usuario</h1>
      {data.length > 0 && (
        <div id="userData" className="container-body">
          <div className="modal-body">
            {/* Información del usuario */}
            <div className="row gy-3 row-column-2 mb-3">
              <h5>Informacion Usuario</h5>
              <div className="col-6">
                <label className="form-label">Primer Nombre:</label>
                <span
                  style={{ height: "38px" }}
                  className="input-group-text  w-75 "
                >
                  {data[0].nombre}
                </span>
              </div>
              <div className="col-6">
                <label className="form-label ">Segundo Nombre:</label>
                <span
                  style={{ height: "38px" }}
                  className="input-group-text  w-75 "
                >
                  {data[0].seg_nombre}
                </span>
              </div>
            </div>
            <div className="row gy-3 mb-3">
              <div className="col-6">
                <label className="form-label">Apellido Paterno:</label>
                <span
                  style={{ height: "38px" }}
                  className="input-group-text  w-75 "
                >
                  {data[0].apellido_p}
                </span>
              </div>
              <div className="col-6">
                <label className="form-label">Apellido Materno:</label>
                <span
                  style={{ height: "38px" }}
                  className="input-group-text  w-75 "
                >
                  {data[0].apellido_m}
                </span>
              </div>
            </div>
            <div className="row gy-3 mb-3">
              <div className="col-6">
                <label className="form-label">Año de Nacimiento:</label>
                <span
                  style={{ height: "38px" }}
                  className="input-group-text  w-75 "
                >
                  {data[0].ano_nacimiento}
                </span>
              </div>
              <div className="col-6">
                <label className="form-label">Sexo:</label>
                <span
                  style={{ height: "38px" }}
                  className="input-group-text  w-75 "
                >
                  {data[0].sexo}
                </span>
              </div>
            </div>
            <div className="row gy-3 mb-3">
              <div className="col-6">
                <label className="form-label">Especialidad:</label>
                <span
                  style={{ height: "38px" }}
                  className="input-group-text  w-75 "
                >
                  {data[0].especialidad}
                </span>
              </div>
              <div className="col-6">
                <label className="form-label">Establecimiento:</label>
                <span
                  style={{ height: "38px" }}
                  className="input-group-text  w-75 "
                >
                  {data[0].establecimiento}
                </span>
              </div>
            </div>

            <div className="row gy-3 mb-3">
              <div className="col-6">
                <label className="form-label">Teléfono:</label>
                <div className="input-group">
                  <span
                    style={{ height: "38px" }}
                    className="input-group-text  w-75 "
                  >
                    (+56) {data[0].telefono}
                  </span>
                </div>
              </div>
              <div className="col-6">
                <label className="form-label">Correo Electrónico:</label>
                <span
                  style={{ height: "38px" }}
                  className="input-group-text w-75 "
                >
                  {data[0].email}
                </span>
              </div>
            </div>
            <div className="row gy-3 mb-3">
              <div className="col-6 col-auto d-flex flex-column">
                <label className="form-check-label">RBD:</label>
                <span
                  style={{ height: "38px" }}
                  className="input-group-text  w-75 "
                >
                  {data[0].rbd}
                </span>
              </div>
            </div>
            {/* ... */}
            <h5>Cambiar contraseña</h5>
            <form onSubmit={handleSubmit}>
              <div className="row gy-3 mb-3">
                <div className="col-6 col-auto d-flex flex-column">
                  <label className="form-check-label">Contraseña actual:</label>
                  <input
                    type="password"
                    className="form-control"
                    name="contrasena"
                    value={formData.contrasena}
                    onChange={handleChange}
                  />
                  {errors.contrasena && (
                    <div className="text-danger">{errors.contrasena}</div>
                  )}
                </div>
                <div className="col-6 col-auto d-flex flex-column">
                  <label className="form-check-label">Contraseña nueva:</label>
                  <input
                    type="password"
                    className="form-control"
                    name="new_contrasena"
                    value={formData.new_contrasena}
                    onChange={handleChange}
                  />
                  {errors.new_contrasena && (
                    <div className="text-danger">{errors.new_contrasena}</div>
                  )}
                </div>
              </div>
              <div className="row gy-3 mb-3">
                <div className="col-6 col-auto d-flex flex-column">
                  <label className="form-check-label">
                    Confirmar contraseña nueva:
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    name="confirm_new_contrasena"
                    value={formData.confirm_new_contrasena}
                    onChange={handleChange}
                  />
                  {errors.confirm_new_contrasena && (
                    <div className="text-danger">
                      {errors.confirm_new_contrasena}
                    </div>
                  )}
                </div>
              </div>
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              <div className="modal-footer gap-2">
                <button type="submit" className="btn btn-primary">
                  Cambiar Contraseña
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
