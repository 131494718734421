import { useState, useEffect } from "react";
import "./modal.css";
import Axios from "axios";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../api/axios";

export function ModalAddActividad() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [formData, setFormData] = useState({
    nombre: "",
    descripcion: "",
    estado: "Inscripción Habilitada",
    fecha_evento: "",
    codigo_proyecto: "",
    imagen: null,
    permiso: null,
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const handleModalClose = () => {
    // Recargar la página
    window.location.reload();
  };
  useEffect(() => {
    // Obtener el modal por su ID
    const modal = document.getElementById("ModalActividad");
    // Añadir un listener para el evento 'hidden.bs.modal'
    modal.addEventListener("hidden.bs.modal", handleModalClose);

    // Limpiar el listener cuando el componente se desmonte
    return () => {
      modal.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []); // La dependencia vacía asegura que este efecto solo se ejecute una vez
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.nombre.trim()) {
      newErrors.nombre = "Nombre es requerido";
      isValid = false;
    }

    if (!formData.imagen) {
      newErrors.imagen = "Imagen es requerida";
      isValid = false;
    }

    if (!formData.permiso) {
      newErrors.permiso = "Cesion de imagen es requerido";
      isValid = false;
    }

    if (!formData.fecha_evento) {
      newErrors.fecha_evento = "Fecha de evento es requerida";
      isValid = false;
    }

    if (!formData.descripcion.trim()) {
      newErrors.descripcion = "Descripción es requerida";
      isValid = false;
    }

    if (!formData.codigo_proyecto.trim()) {
      newErrors.codigo_proyecto = "Codigo de proyecto es requerido";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  /*const addActividad = () => {
    if (!validateForm()) return;

    Axios.post("https://appexploratarapaca.com/sdbmg/actividades", {
      nombre: formData.nombre,
      estado: formData.estado,
      fecha_evento: formData.fecha_evento,
      descripcion: formData.descripcion,
      creado_por: auth.usuario_id,
      codigo_proyecto: formData.codigo_proyecto,
      imagen: formData.imagen,
      permiso: formData.permiso,
    })
      .then(() => {
        console.log("éxito");
        setSuccessMessage("¡La actividad se agregó correctamente!"); // Establecer el mensaje de éxito
      })
      .catch((error) => {
        console.error("Error al agregar actividad:", error);
      });
  };*/

  const addActividad = () => {
    if (!validateForm()) return;

    const formDataToSend = new FormData();
    formDataToSend.append("nombre", formData.nombre);
    formDataToSend.append("estado", formData.estado);
    formDataToSend.append("fecha_evento", formData.fecha_evento);
    formDataToSend.append("descripcion", formData.descripcion);
    formDataToSend.append("creado_por", auth.user_id);
    formDataToSend.append("codigo_proyecto", formData.codigo_proyecto);
    formDataToSend.append("imagen", formData.imagen);
    formDataToSend.append("permiso", formData.permiso);

    axios
      .post("/actividades", formDataToSend)
      .then(() => {
        console.log("Success");
        setSuccessMessage("¡La actividad se agregó correctamente!");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div
      className="modal fade"
      id="ModalActividad"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      {/* Modal content */}
      <div className="modal-dialog" id="modalWindowDocente">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Agregar Actividad
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row gy-3 row-column-2 mb-3">
              <div className="col-6">
                <label className="form-label">Nombre de Actividad</label>
                <input
                  type="text"
                  className={`form-control ${errors.nombre && "is-invalid"}`}
                  value={formData.nombre}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Validate the input using regular expression
                    if (
                      /^[A-Za-z0-9\s'-]+$/.test(inputValue) ||
                      inputValue === ""
                    ) {
                      // If the input is valid or empty, update the form data
                      setFormData({ ...formData, nombre: inputValue });
                    }
                  }}
                />
                {errors.nombre && (
                  <div className="invalid-feedback">{errors.nombre}</div>
                )}
              </div>
              <div className="col-6">
                <label className="form-label">Fecha de Evento</label>
                <input
                  type="date"
                  className={`form-control ${
                    errors.fecha_evento && "is-invalid"
                  }`}
                  value={formData.fecha_evento}
                  onChange={(e) =>
                    setFormData({ ...formData, fecha_evento: e.target.value })
                  }
                />
                {errors.fecha_evento && (
                  <div className="invalid-feedback">{errors.fecha_evento}</div>
                )}
              </div>
            </div>
            <div className="row gy-3 mb-3">
              <div className="col-6">
                <label className="form-label">Descripción</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.descripcion && "is-invalid"
                  }`}
                  value={formData.descripcion}
                  onChange={(e) =>
                    setFormData({ ...formData, descripcion: e.target.value })
                  }
                />
                {errors.descripcion && (
                  <div className="invalid-feedback">{errors.descripcion}</div>
                )}
              </div>
              <div className="col-6">
                <label className="form-label">Estado de Actividad</label>
                <select
                  id="optionsSelect"
                  className="form-select"
                  value={formData.estado}
                  onChange={(e) =>
                    setFormData({ ...formData, estado: e.target.value })
                  }
                >
                  <option value="Inscripción Habilitada">
                    Inscripción Habilitada
                  </option>
                  <option value="Inscripción Deshabilitada">
                    Inscripción Deshabilitada
                  </option>
                  <option value="Actividad Finalizada">
                    Actividad Finalizada
                  </option>
                </select>
              </div>
            </div>
            <div className="row gy-3 mb-3">
              <div className="col-6">
                <label className="form-label">Agregar imagen</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    setFormData({ ...formData, imagen: e.target.files[0] })
                  }
                />
                {errors.imagen && (
                  <div className="invalid-feedback">{errors.imagen}</div>
                )}
              </div>
              <div className="col-6">
                <label className="form-label">
                  Agregar permiso de cesión de imagen
                </label>
                <input
                  type="file"
                  accept=".pdf"
                  onChange={(e) =>
                    setFormData({ ...formData, permiso: e.target.files[0] })
                  }
                />
                {errors.permiso && (
                  <div className="invalid-feedback">{errors.permiso}</div>
                )}
              </div>
            </div>
            <div className="row gy-3 mb-3">
              <div className="col-6">
                <label className="form-label">Codigo de Proyecto</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.codigo_proyecto && "is-invalid"
                  }`}
                  value={formData.codigo_proyecto}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      codigo_proyecto: e.target.value,
                    })
                  }
                />
                {errors.codigo_proyecto && (
                  <div className="invalid-feedback">
                    {errors.codigo_proyecto}
                  </div>
                )}
              </div>
            </div>
            {successMessage && ( // Mostrar el mensaje de éxito si existe
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={addActividad}
            >
              Listo
            </button>

            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
