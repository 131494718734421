import { useState } from "react";

export function ModalContactoRegistro() {
  const [formData, setFormData] = useState({
    mensaje: "",
    nombres: "",
    apellidos: "",
    telefono: "",
    correoElectronico: "",
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState(""); // Nuevo estado para el mensaje de éxito

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.mensaje.trim()) {
      newErrors.mensaje = "Mensaje es requerido";
      isValid = false;
    }

    if (!formData.nombres.trim()) {
      newErrors.nombres = "Nombres son requeridos";
      isValid = false;
    }

    if (!formData.apellidos.trim()) {
      newErrors.apellidos = "Apellidos son requeridos";
      isValid = false;
    }

    if (!formData.telefono.trim()) {
      newErrors.telefono = "Teléfono es requerido";
      isValid = false;
    }

    if (!formData.correoElectronico.trim()) {
      newErrors.correoElectronico = "Correo Electrónico es requerido";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    // Aquí iría la lógica para enviar el formulario, como por ejemplo una llamada a una API

    setSuccessMessage("¡Mensaje enviado correctamente!"); // Establecer el mensaje de éxito
    // Limpiar el formulario
    setFormData({
      mensaje: "",
      nombres: "",
      apellidos: "",
      telefono: "",
      correoElectronico: "",
    });
  };

  return (
    <div
      className="modal fade"
      tabIndex="-1"
      aria-labelledby="ModalContactoRegistro"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      role="dialog"
      id="ModalContactoRegistro"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Contáctenos</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Cerrar"
            ></button>
          </div>
          <form className="modal-body">
            <div className="mb-3">
              <label className="form-label" htmlFor="MensajeTextArea">
                Mensaje:
              </label>
              <textarea
                className={`form-control ${errors.mensaje && "is-invalid"}`}
                id="MensajeTextArea"
                rows="6"
                value={formData.mensaje}
                onChange={(e) =>
                  setFormData({ ...formData, mensaje: e.target.value })
                }
              ></textarea>
              {errors.mensaje && (
                <div className="invalid-feedback">{errors.mensaje}</div>
              )}
            </div>
            <div className="d-flex my-2 gap-4">
              <div>
                <label className="form-label" htmlFor="TextNombre">
                  Nombres:
                </label>
                <input
                  type="text"
                  className={`form-control ${errors.nombres && "is-invalid"}`}
                  id="TextNombre"
                  value={formData.nombres}
                  onChange={(e) =>
                    setFormData({ ...formData, nombres: e.target.value })
                  }
                />
                {errors.nombres && (
                  <div className="invalid-feedback">{errors.nombres}</div>
                )}
              </div>
              <div>
                <label className="form-label" htmlFor="TextApellidos">
                  Apellidos:
                </label>
                <input
                  type="text"
                  className={`form-control ${errors.apellidos && "is-invalid"}`}
                  id="TextApellidos"
                  value={formData.apellidos}
                  onChange={(e) =>
                    setFormData({ ...formData, apellidos: e.target.value })
                  }
                />
                {errors.apellidos && (
                  <div className="invalid-feedback">{errors.apellidos}</div>
                )}
              </div>
            </div>
            <div className="d-flex my-2 gap-4">
              <div>
                <label className="form-label" htmlFor="TextTelefono">
                  Teléfono:
                </label>
                <input
                  type="text"
                  className={`form-control ${errors.telefono && "is-invalid"}`}
                  id="TextTelefono"
                  value={formData.telefono}
                  onChange={(e) =>
                    setFormData({ ...formData, telefono: e.target.value })
                  }
                />
                {errors.telefono && (
                  <div className="invalid-feedback">{errors.telefono}</div>
                )}
              </div>
              <div>
                <label className="form-label" htmlFor="TextCorreoElectronico">
                  Correo Electrónico:
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.correoElectronico && "is-invalid"
                  }`}
                  id="TextCorreoElectronico"
                  value={formData.correoElectronico}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      correoElectronico: e.target.value,
                    })
                  }
                />
                {errors.correoElectronico && (
                  <div className="invalid-feedback">
                    {errors.correoElectronico}
                  </div>
                )}
              </div>
            </div>
          </form>
          <div className="modal-footer justify-content-center">
            <button
              type="button"
              className="btn btn-primary btn-xl"
              onClick={handleSubmit}
            >
              Enviar
            </button>
          </div>
          {successMessage && ( // Mostrar el mensaje de éxito si existe
            <div className="alert alert-success" role="alert">
              {successMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
