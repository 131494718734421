import { Outlet } from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import { Navbar } from "../Components/Navbar/Navbar";

const Layout = () => {
  return (
    <main className="App d-flex flex-column justify-content-between  min-vh-100">
      <Navbar />
      <Outlet />
      <Footer />
    </main>
  );
};

export default Layout;
