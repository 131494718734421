import Login from "./Pages/Login2";
import Layout from "./Pages/Principal";
import { Actividades } from "./Pages/Actividades";
import { ActividadesDocentes } from "./Pages/ActividadesDocente";
import { Participantes } from "./Pages/Participantes";
import { Docentes } from "./Pages/Docentes";
import { Routes, Route } from "react-router-dom";
import RequireAuth from "./Components/RequireAuth";
import { ParticipanteInfo } from "./Pages/ParticipantesInfo";
import { DocenteInfo } from "./Pages/DocentesInfo";
import { DatosUser } from "./Pages/DatosUsuario";
import { Missing } from "./Pages/Missing";
import { Unauthorized } from "./Pages/Unauthorized";
function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />

      <Route /*element={<RequireAuth allowedRoles={["docente"]} />}*/>
        <Route path="/" element={<Layout />}>
          {/**Docente */}
          <Route element={<RequireAuth allowedRoles={"admin"} />}>
            <Route path="docentes" element={<Docentes />}></Route>
            <Route path="usuarios/:user_id" element={<DocenteInfo />}></Route>
          </Route>
          <Route path="userinfo/" element={<DatosUser />}></Route>
          <Route path="participantes" element={<Participantes />}></Route>

          {/**Admin */}
          <Route path="actividades" element={<Actividades />} />

          <Route
            path="actividades/:actividad_id"
            element={<ActividadesDocentes />}
          />
          <Route
            path="participantes/:participante_id"
            element={<ParticipanteInfo />}
          ></Route>
        </Route>
      </Route>
      <Route path="/unauthorized" element={<Unauthorized />}></Route>

      <Route path="/*" element={<Missing />}></Route>
    </Routes>
  );
}
export default App;
