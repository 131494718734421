import { TablaDocenteParticipante } from "../Components/Table/TablaDocenteParticipante";
import useAuth from "../hooks/useAuth";

export function Participantes() {
  const { auth } = useAuth();
  const admin = auth.roles === "admin";
  console.log(auth.roles);
  return (
    <div className="container main-body pb-3 my-5 ">
      <h1>Participantes</h1>
      {admin ? (
        <p>
          Seleccione participantes para modificar datos o haga clic en el botón
          "Agregar participantes" para registrar nuevos participantes.
        </p>
      ) : (
        <p>
          Seleccione un participante para modificar datos o haga clic en el
          botón "Nuevo Participante" para registrar un nuevo participante.
        </p>
      )}
      <div className="table-container container">
        <TablaDocenteParticipante id={auth.user_id} />
      </div>
    </div>
  );
}
