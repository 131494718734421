function Footer() {
  return (
    <footer className="text-center mb-n4 mt-auto text-lg-start bg-primary">
      <div className="container d-flex justify-content-center py-2">
        <button
          type="button"
          className="btn btn-primary btn-lg btn-floating mx-2"
          style={{ backgroundColor: "#54456b" }}
          onClick={() =>
            window.open("https://www.facebook.com/exploratarapaca", "_blank")
          }
        >
          <i className="fab fa-facebook-f"></i>
        </button>
        <button
          type="button"
          className="btn btn-primary btn-lg btn-floating mx-2"
          style={{ backgroundColor: "#54456b" }}
          onClick={() =>
            window.open("https://www.youtube.com/@Explora_Tarapaca", "_blank")
          }
        >
          <i className="fab fa-youtube"></i>
        </button>
        <button
          type="button"
          className="btn btn-primary btn-lg btn-floating mx-2"
          style={{ backgroundColor: "#54456b" }}
          onClick={() =>
            window.open("https://www.instagram.com/exploratarapaca/", "_blank")
          }
        >
          <i className="fab fa-instagram"></i>
        </button>
        <button
          type="button"
          className="btn btn-primary btn-lg btn-floating mx-2"
          style={{ backgroundColor: "#54456b" }}
          onClick={() =>
            window.open("https://twitter.com/ExploraTarapaca", "_blank")
          }
        >
          <i className="fab fa-x-twitter"></i>
        </button>
        <button
          type="button"
          className="btn btn-primary btn-lg btn-floating mx-2"
          style={{ backgroundColor: "#54456b" }}
          onClick={() =>
            window.open("https://www.tiktok.com/@exploratarapaca", "_blank")
          }
        >
          <i className="fab fa-tiktok"></i>
        </button>
        <button
          type="button"
          className="btn btn-primary btn-lg btn-floating mx-2"
          style={{ backgroundColor: "#54456b" }}
          onClick={() =>
            window.open(
              "https://open.spotify.com/show/75ZbnvVxKVQAr3nNxrE2Ot?si=dec443648b554c17",
              "_blank"
            )
          }
        >
          <i className="fab fa-spotify"></i>
        </button>
      </div>

      <div
        className="text-center text-white p-1"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        <a className="text-white" href="https://www.explora.cl/tarapaca/">
          ¡Conoce mas sobre nosotros!
        </a>
      </div>
    </footer>
  );
}

export default Footer;
