import "./Login2.css";
import { useState, useRef, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { ModalContactoRegistro } from "../Components/Modals/ModalSolicitudRegistro";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios, { axiosPrivate } from "../api/axios";

const LOGIN_URL = "/auth";

const Login = () => {
  const { setAuth } = useAuth();
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/actividades";
 // const axiosPrivate = useAxiosPrivate();
  const [email, setemail] = useState("");
  const [pwd, setPwd] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = axiosPrivate.post(
        LOGIN_URL,
        JSON.stringify({
          email: email,
          contraseña: pwd,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles;
      const roles2 = response?.data?.roles2;
      const user_id = response?.data?.user_id;
      const nombre_completo = response?.data?.nombre_completo;
      setAuth({ user_id, roles, roles2, accessToken, nombre_completo });

      setemail("");
      setPwd("");
      navigate(from, {
        replace: true,
      });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No hay respuesta del servidor");

        console.log(errMsg);
      } else if (err.response?.status === 400) {
        setErrMsg("Falta nombre de user o contraseña");

        console.log(errMsg);
      } else if (err.response?.status === 401) {
        setErrMsg("Correo electronico o contraseña erronea");

        console.log(errMsg);
      } else {
        setErrMsg("Error al iniciar sesión");

        console.log(errMsg);
      }
    }
  };

  return (
    <section>
      <section id="base2" className="background  d-flex">
        <div
          className="vh-100 vw-100   d-flex align-items-center  justify-content-center"
          style={{ backdropFilter: "blur(5px)" }}
        >
          <div
            className="login-card bg-white d-flex
          flex-column text-black rounded-5 p-2"
          >
            <div className="align-self-stretch d-flex flex-column vh-100 p-3 m-2">
              <div className="d-flex flex-column justify-content-around align-items-center">
                <div className="justify-content-center mb-5 d-flex">
                  <img
                    src={process.env.PUBLIC_URL + "/images/Logo_Explora.png"}
                    className="w-25"
                  />
                </div>

                <span className="h1 fw-bold mb-0">Explora Tarapacá</span>
              </div>
              <form
                onSubmit={handleSubmit}
                className="d-flex flex-fill justify-content-center py-0 px-0 mt-3 flex-column"
              >
                <div className="d-flex form-outline form-floating mb-5">
                  <input
                    placeholder="name@example.com"
                    type="text"
                    className="form-control"
                    id="email"
                    autoComplete="off"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                    required
                  />
                  <label htmlFor="email" className="text-black">
                    Correo Electrónico
                  </label>
                </div>
                <div className="d-flex form-outline form-floating mb-5">
                  <input
                    placeholder="name@example.com"
                    type="password"
                    className="form-control"
                    id="pwd"
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                    required
                  />
                  <label htmlFor="pwd" className="text-black">
                    Contraseña
                  </label>
                </div>
                {errMsg && (
                  <div className="alert alert-danger" role="alert">
                    {errMsg}
                  </div>
                )}
                <div className="pt-1 mb-4">
                  <button
                    id="button-login"
                    className="btn w-100 btn-info btn-lg btn-block"
                    type="submit"
                  >
                    Iniciar sesión
                  </button>
                </div>
              </form>
              <div>
                {/* <p>
                  ¿No tiene cuenta?
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#ModalContactoRegistro"
                    href="#ModalContactoRegistro"
                    className="link-info"
                  >
                    Contáctenos
                  </a>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ModalContactoRegistro />
    </section>
  );
};

export default Login;
