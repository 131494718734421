export function Unauthorized() {
  return (
    <div className="container main-body pb-3 my-5">
      <section className="flex items-center h-full p-16 dark:bg-gray-50 dark:text-gray-800">
        <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
          <div className="max-w-md text-center">
            <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-400">
              <span className="sr-only">Error</span>
            </h2>
            <p className="text-2xl font-semibold md:text-3xl">
              Usted no tiene la autorizacion para esta seccion.
            </p>
            <p className="mt-4 mb-8 dark:text-gray-600">
              Inicie sesion con otra cuenta o retroceda.
            </p>
            {/* <a
              rel="noopener noreferrer"
              href="https://appexploratarapaca.com/"
              className="px-8 py-3 font-semibold rounded dark:bg-violet-600 dark:text-gray-50"
            >
              Inicio
            </a> */}
          </div>
        </div>
      </section>
    </div>
  );
}
