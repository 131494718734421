import "./table.css";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Button } from "react-bootstrap";

export function TableParticipante({ actividadId }) {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [descarga, setDescarga] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const headers = [
    "CODIGO PROYECTO",
    "NOMBRE ACTIVIDAD",
    "TIPO PARTICIPANTE",
    "DOCUMENTO IDENTIFICACION PARTICIPANTE",
    "RUT PARTICIPANTE",
    "DIGITO VERIFICADOR PARTICIPANTE",
    "DNI PARTICIPANTE",
    "PRIMER NOMBRE PARTICIPANTE",
    "PRIMER APELLIDO PARTICIPANTE",
    "SEGUNDO APELLIDO PARTICIPANTE",
    "ANIO NACIMIENTO PARTICIPANTE",
    "SEXO PARTICIPANTE",
    "ESPECIALIDAD PARTICIPANTE",
    "CORREO ELECTRONICO PARTICIPANTE",
    "TELEFONO PARTICIPANTE",
    "CICLO PARTICIPANTE",
    "CURSO PARTICIPANTE",
    "REGION PARTICIPANTE",
    "PROVINCIA PARTICIPANTE",
    "COMUNA PARTICIPANTE",
    "CODIGO INVESTIGACION CONGRESO",
    "TIPO PARTICIPACION CONGRESO",
    "NO APLICA RBD",
    "RBD",
    "CONOCE RUT-DNI FAMILIAR",
    "DOCUMENTO IDENTIFICACION FAMILIAR",
    "RUT FAMILIAR",
    "DIGITO VERIFICADOR FAMILIAR",
    "DNI FAMILIAR",
    "PRIMER NOMBRE FAMILIAR",
    "PRIMER APELLIDO FAMILIAR",
    "SEGUNDO APELLIDO FAMILIAR",
    "ANIO NACIMIENTO FAMILIAR",
    "SEXO FAMILIAR",
    "CORREO ELECTRONICO FAMILIAR",
    "TELEFONO MOVIL FAMILIAR",
  ];

  useEffect(() => {
    fetchDataDescarga();
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://appexploratarapaca.com/sdbmg/participantes/docente/${actividadId}`
      );

      if (!response.ok) {
        throw new Error(`¡Error HTTP! Estado: ${response.status}`);
      }

      const jsonData = await response.json(); // Extraer datos JSON
      setData(jsonData);
      setFilteredData(jsonData);
    } catch (error) {
      console.error("Error al obtener datos:", error);
    }
  };
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const fetchDataDescarga = async () => {
    try {
      const response = await fetch(
        `https://appexploratarapaca.com/sdbmg/participantes/descarga/${actividadId}` // Actualizar con tu URL real
      );

      if (!response.ok) {
        throw new Error(`¡Error HTTP! Estado: ${response.status}`);
      }

      const jsonData = await response.json();
      setDescarga(jsonData);
    } catch (error) {
      console.error("Error al obtener datos de descarga:", error);
    }
  };
  useEffect(() => {
    // Filter data based on searchQuery
    const filtered = data.filter(
      (item) =>
        item.participante_nombre
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.participante_apellido_p
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.participante_apellido_m
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.participante_curso
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.participante_ciclo
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    // Set filteredData state
    setFilteredData(filtered);
  }, [searchQuery, data]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }
    return (
      <nav aria-label="Page navigation example ">
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <span aria-hidden="true">&laquo;</span>
            </button>
          </li>
          {pageNumbers}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <span aria-hidden="true">&raquo;</span>
            </button>
          </li>
        </ul>
      </nav>
    );
  };

  return (
    <div className="rounded tb-container" style={{ margin: "20px" }}>
      <div className="search-container">
        <input
          className="search-input"
          type="text"
          placeholder="Ingrese Nombre, Fecha o Estado para buscar actividad..."
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
      <table className="caption-top table rounded table-sm table-bordered table-hover table-striped">
        <caption>Lista de Participantes</caption>
        <thead className="table-primary">
          <tr>
            <th>Apellidos</th>
            <th>Nombre</th>
            <th>Ciclo</th>
            <th>Curso</th>
            <th>Agregado por:</th>
            <th>RBD</th>
          </tr>
        </thead>

        <tbody>
          {currentItems.map((item) => (
            <tr key={item.participante_id}>
              <td>
                {item.participante_apellido_p}, {item.participante_apellido_m}
              </td>
              <td>{item.participante_nombre}</td>
              <td>{item.participante_ciclo}</td>
              <td>{item.participante_curso}</td>
              <td>
                {item.docente_nombre} {item.docente_apellido_p}{" "}
                {item.docente_apellido_m}
              </td>
              <td>{item.participante_rbd}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="d-flex justify-content-between align-items-start">
        <div>
          {descarga.length > 0 && (
            <CSVLink
              data={descarga}
              headers={headers}
              filename={"participantes.csv"}
            >
              <Button variant="primary">Descargar CSV</Button>
            </CSVLink>
          )}
          {filteredData.length === 0 && (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              No hay datos disponibles.
            </div>
          )}
        </div>
        <div className="d-flex  align-items-end">
          <div className="d-flex flex-grow-1 justify-content-between align-items-center mx-1">
            {renderPagination()}
          </div>
        </div>
      </div>
    </div>
  );
}
